import React from "react";
// styles
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
// icons
import arrowLeftIcon from "assets/icons/arrow-left.svg";
import arrowRightIcon from "assets/icons/arrow-right.svg";

interface IQuantity {
  value?: number | string;
  id: string;
  menu?: string;
  onChange?: ({ target }: { target: EventTarget | null }) => void;
  setValues?: (field: string, value: any, shouldValidate?: boolean) => void;
  isPrintMenu?: boolean;
}

const cn = classNames.bind(styles);

const Quantity: React.FC<IQuantity> = ({
  value = 0,
  id,
  setValues = () => {},
  menu,
  isPrintMenu,
}) => {
  const increment = () => {
    setValues(
      `${menu}[${id}]`,
      Number(value) >= 9999 ? value : Number(value) + 1,
    );
  };

  const decrement = () => {
    setValues(
      `${menu}[${id}]`,
      Number(value) === 0 ? value : Number(value) - 1,
    );
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    (+newValue || newValue === "") && setValues(`${menu}[${id}]`, newValue);
  };

  const handleFocus = (e: any) => {
    value === 0 && setValues(`${menu}[${id}]`, "");
  };

  const handleBlur = (e: any) => {
    !value && setValues(`${menu}[${id}]`, 0);
  };

  return (
    <div className={cn("qnt")}>
      {menu && !isPrintMenu && (
        <span className={cn("qnt-arrow", "qnt-arrow_left")} onClick={decrement}>
          <img src={arrowLeftIcon} alt="" className={cn("qnt-arrow-icon")} />
        </span>
      )}
      <div className={cn("qnt-number")}>
        <input
          value={`${Math.abs(+value)}`}
          type="number"
          id={`${id}`}
          autoComplete="off"
          name={`${id}`}
          className={cn("qnt-number__input")}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onInput={handleChange}
        />
      </div>
      {menu && !isPrintMenu && (
        <div className={cn("qnt-arrow", "qnt-arrow_right")} onClick={increment}>
          <img src={arrowRightIcon} alt="" className={cn("qnt-arrow-icon")} />
        </div>
      )}
    </div>
  );
};

export default Quantity;
