import React, { useCallback, useMemo } from "react";
// components
import Select from "react-select";
// redux
import { useDispatch, useSelector } from "react-redux";
import { selectClassroom } from "store/reducers/classroom/selectors";
import { setClassroom } from "store/reducers/classroom";
import { selectUser } from "store/reducers/user/selectors";
// hooks
import { useWindowDimensions } from "hooks/useWindowDimensions";
// type
import { TClassRoom } from "store/reducers/classroom/type";
// icons
import dropDownIcon from "assets/icons/dropdown.svg";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cn = classNames.bind(styles);

const colourStyles = {
  control: (styles: any) => ({
    ...styles,
    border: "none",
    width: "230px",
    minHeight: "22px",
    maxHeight: "22px",
    "@media (max-width: 400px)": {
      width: "200px",
    },
    boxShadow: "0 0 0 1px #64ad5",

    ":hover": {
      borderColor: "#83867e",
    },
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: "22px",
  }),
  valueContainer: (base: any) => ({
    ...base,
    height: "18px",
  }),

  indicatorSeparator: () => ({
    minHeight: "18px",
    maxHeight: "18px",
  }),
  option: () => ({
    color: "#83867e",
    overflow: "hidden",
    cursor: "pointer",
    padding: "5px 10px",
    ":hover": {
      backgroundColor: "#64ad57",
      color: "#ffffff",
    },
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    color: "#9BB0CB",
    fontSize: "14px",
    width: "15px",
    height: "18px",
    padding: "0",
    margin: "0",

    "&:hover": {
      color: "#9BB0CB",
    },
  }),
  singleValue: () => ({
    fontWeight: 500,
    lineHeight: "16px",
    color: "#64AD57",
    overflow: "hidden",
    height: "18px",
  }),
};

const DropdownIndicator = () => {
  return (
    <span className={cn("dropdown")}>
      <img src={dropDownIcon} alt="" />
    </span>
  );
};

const SelectClassroom: React.FC = () => {
  const value = useSelector(selectClassroom);
  const { customer } = useSelector(selectUser);
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();

  const options = useMemo(
    () =>
      customer?.rooms?.map(
        (room): TClassRoom => ({
          label: room.name,
          value: room.id,
        })
      ),
    [customer]
  );

  const defaultValue = useMemo(
    () =>
      value && value?.label.length > 18
        ? width > 400
          ? { label: `${value.label.slice(0, 18)}...`, value: value.value }
          : { label: `${value.label.slice(0, 15)}...`, value: value.value }
        : value,
    [width, value]
  );

  const selectHandler = useCallback(
    (val: TClassRoom) => {
      dispatch(setClassroom(val));
    },
    [dispatch]
  );

  return (
    <div className={cn("select")}>
      <span className={cn("title")}>Classroom:</span>
      <Select
        className="basic-single"
        classNamePrefix="select"
        isMulti={false}
        defaultValue={defaultValue}
        isSearchable={false}
        components={{ DropdownIndicator }}
        name="color"
        styles={colourStyles}
        options={options}
        onChange={(val) => selectHandler(val as TClassRoom)}
        placeholder="Choose the classroom"
      />
    </div>
  );
};

export default SelectClassroom;
