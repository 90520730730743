import { useState } from "react";
// hooks
import { useSelector } from "react-redux";
import { useRequest } from "hooks/useRequest";
// redux
import { selectToken } from "store/reducers/user/selectors";
import { useDispatch } from "react-redux";
import { setCustomer } from "store/reducers/user";
// api
import { urlPostEmailChange } from "utils/requests";

export const useEmail = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<string | null>(null);
  const { postRequest, error } = useRequest();

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const changeEmail = async (email: string, password: string) => {
    try {
      setIsLoading(true);
      setResponse(null);

      const result = await postRequest(
        urlPostEmailChange,
        { email, password },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result) {
        const { data } = result;

        dispatch(setCustomer(data));
        setResponse("E-mail was changed");
        setIsLoading(false);
        return true;
      }

      setIsLoading(false);
      return false;
    } catch (e) {
      setIsLoading(false);
    }
  };

  return { isLoading, error, response, changeEmail };
};
