import { useCallback, useState } from "react";
// hooks
import { useRequest } from "hooks/useRequest";
import { useSelector } from "react-redux";
// redux
import { selectToken } from "store/reducers/user/selectors";
// api
import { urlOrders } from "utils/requests";
// utils
import { getFirstAndLastDayOfMonth } from "utils/date";

export const useCalendar = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getRequest } = useRequest();

  const token = useSelector(selectToken);

  const getDailyMenuPerMonth = useCallback(
    async (orderDate: Date | string, room: string | number) => {
      try {
        setIsLoading(true);

        const { startDay, endDay } = getFirstAndLastDayOfMonth(
          new Date(orderDate)
        );

        const url = `${urlOrders}?roomId=${room}&startDate=${startDay}&endDate=${endDay}`;

        const result = await getRequest(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsLoading(false);
        return result;
      } catch (e) {
        console.error(e);
        setIsLoading(false);
      }
    },
    [token]
  );

  return {
    isLoading,
    getDailyMenuPerMonth,
  };
};
