import React, { useState, useEffect } from "react";
// hooks
import { useHistory } from "react-router-dom";
import { useCalendar } from "hooks/useCalendar";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { useHolidays } from "hooks/useHolidays";
// redux
import { useSelector, useDispatch } from "react-redux";
import { selectClassroom } from "store/reducers/classroom/selectors";
import { selectDate } from "store/reducers/selectedDate/selectors";
import { setSelectedDate } from "store/reducers/selectedDate";
// components
import AsideWrapper from "components/Aside/AsideWrapper";
import Calendar from "components/Calendar";
import SelectClassroom from "components/SelectClassroom";
import Loader from "components/Loader";
import MonthSelector from "components/Select/Month";
import Weeks from "components/Select/Weeks";
import WeekRange from "components/WeekRange";
import Stats from "components/Stats";
// routing
import { CUSTOMER_ROUTES } from "pages/routes";
// types
import { TOrder } from "./type";

const Order: React.FC = () => {
  const [menuItems, setMenuItems] = useState<TOrder[] | null>(null);

  const { getDailyMenuPerMonth, isLoading } = useCalendar();
  const { isLoading: isHolidaysLoading } = useHolidays();

  const dispatch = useDispatch();
  const history = useHistory();
  const { width } = useWindowDimensions();

  const classroom = useSelector(selectClassroom);
  const { date: selectedDate } = useSelector(selectDate);

  const navToRoomOrder = (date: Date | string) => {
    if (classroom) {
      history.push(
        `${CUSTOMER_ROUTES.ROOM}/daily-menus?menuDate=${date}&roomId=${classroom.value}`
      );
    }
  };

  const onClickMonth = (monthDate: Date) => {
    dispatch(setSelectedDate(monthDate));
  };

  const onWeekClick = (week: Date) => {
    dispatch(setSelectedDate(week));
  };

  useEffect(() => {
    classroom?.value &&
      getDailyMenuPerMonth(selectedDate, classroom?.value).then((res) => {
        if (res) {
          setMenuItems(res.data);
        }
      });
  }, [classroom, getDailyMenuPerMonth, selectedDate]);

  return (
    <AsideWrapper>
      <Loader isLoading={isLoading || isHolidaysLoading}>
        <div className="container">
          <div className="max-container">
            {classroom?.label ? (
              <div className="head-container">
                <MonthSelector onClick={onClickMonth} />
                <div className="head-container__stats">
                  <Stats menuItems={menuItems} />
                </div>

                {width < 901 && (
                  <>
                    <Weeks date={selectedDate} onWeekClick={onWeekClick} />
                    <WeekRange date={selectedDate} />
                  </>
                )}
                <SelectClassroom />
                <Calendar dayClick={navToRoomOrder} menuItems={menuItems} />
              </div>
            ) : (
              <div className="without-menu">
                <p>
                  Classroom Error - please contact Administrator for details.
                </p>
              </div>
            )}
          </div>
        </div>
      </Loader>
    </AsideWrapper>
  );
};

export default Order;
