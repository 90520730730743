import moment from "moment";
import { getMonthDayYear } from "utils/date";

export const momentDate = (currentDate: Date | string) => {
  const m = moment(currentDate);

  const start = m.startOf("week").format("DD"),
    end = m.endOf("week").format("DD"),
    month = m.endOf("week").format("MM"),
    // monthName = m.endOf("week").format("MMMM"),
    afterMonth = start > end ? m.startOf("week").format("MM") : "",
    // afterMonthName = start > end ? m.startOf("week").format("MMMM") : monthName,
    year = m.startOf("week").format("YYYY");

  const monday = moment
    .tz(
      new Date(+year, afterMonth ? +afterMonth - 1 : +month - 1, +start + 1),
      "America/Chicago"
    )
    .toDate();

  const tuesday = new Date(monday);
  tuesday.setDate(monday.getDate() + 1);

  const wednesday = new Date(monday);
  wednesday.setDate(monday.getDate() + 2);

  const thursday = new Date(monday);
  thursday.setDate(monday.getDate() + 3);

  const friday = new Date(monday);
  friday.setDate(monday.getDate() + 4);

  const selectedWeek = `${getMonthDayYear(monday)} - ${getMonthDayYear(
    friday
  )}`;

  const isSameDay = (firstDate: string | Date, secondDate: Date) => {
    const isMinusTimeZone = secondDate.getTimezoneOffset() / 60;

    return moment
      .tz(secondDate, isMinusTimeZone > 0 ? "UTC" : "America/Chicago")
      .isSame(moment.tz(firstDate, "America/Chicago"), "day");
  };

  return {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    selectedWeek,
    isSameDay,
  };
};
