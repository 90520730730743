import { useState, useCallback } from "react";
// hooks
import { useRequest } from "hooks/useRequest";
import { useSelector } from "react-redux";
// redux
import { selectToken } from "store/reducers/user/selectors";
// api
import { urlDailyMenu, urlOrders } from "utils/requests";
// types
import { TSaveOrder, TSkipOrder } from "pages/Customer/Room/type";

export const useOrder = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { getRequest, postRequest, putRequest, error } = useRequest();

  const token = useSelector(selectToken);

  const getOrder = useCallback(
    async (menuDate: string, roomId: string) => {
      try {
        setIsLoading(true);

        const url = `${urlDailyMenu}?menuDate=${menuDate}&roomId=${roomId}`;

        const result = await getRequest(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        setIsLoading(false);

        return result?.data;
      } catch (e) {
        setIsLoading(false);
      }
    },
    [token]
  );

  const saveOrder = useCallback(
    async (data: TSaveOrder, orderId?: string | number) => {
      try {
        const url = `${urlOrders}${orderId ? `/${orderId}` : ""}`;
      
        if (orderId) {
          const result = await putRequest(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return result;
        } else {
          const result = await postRequest(urlOrders, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return result;
        }
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  const skipOrder = useCallback(
    async (data: TSkipOrder, orderId?: string | number) => {
      try {
        if (orderId) {
          const url = `${urlOrders}/${orderId}`;

          await putRequest(url, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        } else {
          await postRequest(urlOrders, data, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    []
  );

  return {
    isLoading,
    getOrder,
    saveOrder,
    skipOrder,
    error,
  };
};
