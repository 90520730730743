import React from "react";
// styles
import styles from "../styles.module.scss";
import classNames from "classnames/bind";

interface ITHeader {
  dailyMenuName: string;
  unresponsive?: boolean;
  quantity?: number;
}

const cn = classNames.bind(styles);

const THeader: React.FC<ITHeader> = ({
  dailyMenuName,
  unresponsive = false,
  quantity,
}) => {
  return (
    <div
      className={cn("table-header", {
        "table-header_responsive": !unresponsive,
      })}
    >
      <span className={cn("table-header__daily-menu")}>{dailyMenuName}</span>
      <div
        className={cn("features", {
          features_responsive: !unresponsive,
        })}
      >
        <span className={cn("table-header__qnt", "table-qnt")}>
          {quantity || "Qnt"}
        </span>
        <span
          className={cn("table-category-name", {
            "table-category-name_responsive": !unresponsive,
          })}
        >
          Category
        </span>
      </div>
    </div>
  );
};

export default THeader;
