import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "../styles.module.scss";

interface IMenuItem {
  icon: string;
  title: string;
  onClick?: () => void;
  isActive?: boolean;
}

const cn = classNames.bind(styles);

const MenuItem: React.FC<IMenuItem> = ({ icon, title, onClick, isActive }) => {
  return (
    <li
      className={cn("menu-item", isActive && "active-item")}
      onClick={onClick}
    >
      <div className={cn("menu-item--box")}>
        <img src={icon} alt="" className={cn("menu-item--img")} />
        <span className={cn("menu-item--text")}>{title}</span>
      </div>
    </li>
  );
};

export default MenuItem;
