import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cn = classNames.bind(styles);

interface ITitle {
  text: string;
}

const Title: React.FC<ITitle> = ({ text }) => {
  return <h1 className={cn("title")}>{text}</h1>;
};

export default Title;
