import React from "react";
import { Switch, Route } from "react-router-dom";
// hooks
import { useHistory } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
import { selectUser } from "store/reducers/user/selectors";
// pages
import Order from "pages/Customer/Order";
import Profile from "pages/Customer/Profile";
import Room from "pages/Customer/Room";
import NotFound from "pages/NotFound";
// routing
import { CUSTOMER_ROUTES, APP_ROUTES } from "pages/routes";

const Customer: React.FC = () => {
  const user = useSelector(selectUser);
  const history = useHistory();

  const { token } = user;

  !token && history.push(APP_ROUTES.AUTH);

  return (
    <Switch>
      <Route exact path={CUSTOMER_ROUTES.ORDER} component={Order} />
      <Route exact path={CUSTOMER_ROUTES.PROFILE} component={Profile} />
      <Route path={CUSTOMER_ROUTES.ROOM} component={Room} />

      <Route to="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default Customer;
