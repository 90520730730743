import React from "react";
import TSectionTitle from 'components/Table/TSectionTitle'
// styles
import styles from "./styles.module.scss";
import classNames from "classnames/bind";

const cn = classNames.bind(styles);

interface INotes {
  id: string;
  onChange?: ({ target }: { target: EventTarget | null }) => void;
  value: string;
  styleWrap?: string
}

const Notes: React.FC<INotes> = ({ id, onChange, value, styleWrap }) => {
  return (
    <div className={cn("notes", styleWrap)}>
      <TSectionTitle title="Special lunch Notes" />

      <textarea
        id={id}
        onChange={onChange}
        value={value}
        className={cn("notes__textarea")}
        placeholder="Add commnets"
      />
    </div>
  );
};

export default Notes;
