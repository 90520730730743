import schedIcon from "assets/icons/days/sched.svg";
import notSchedIcon from "assets/icons/days/notSched.svg";
import skipIcon from "assets/icons/days/skip.svg";

export type TStatus = "scheduled" | "cancel" | "not-confirm";

export const getTitle = (_status?: string) => {
  const status = _status?.toLowerCase();

  switch (status) {
    case "scheduled":
      return "Scheduled";
    case "skipped": {
      return "Skipped";
    }
    case "not-confirm": {
      return "Not Scheduled";
    }
    default:
      return "Not Scheduled";
  }
};

export const getImage = (_status?: string) => {
  const status = _status?.toLowerCase();

  switch (status) {
    case "scheduled":
      return schedIcon;
    case "skipped": {
      return skipIcon;
    }
    default: {
      return notSchedIcon;
    }
  }
};

export const getEvent = (event: any) =>
  event.mealTimes.map((el: string) => ({
    date: new Date(event.date),
    title: el,
    className: "event",
  }));
