import React from "react";
// hooks
import { useForm } from "react-hook-form";
import { useProfile } from "hooks/useProfile";
// components
import Field from "components/Forms/Item/Field";
import SaveBtn from "components/Button/Save";
import CancelBtn from "components/Button/Cancel";
import SubTitle from "components/SubTitle";
// utils
import { passwordSchema } from "utils/validation";
// styles
import classNames from "classnames/bind";
import styles from "components/Forms/styles.module.scss";

export type Inputs = {
  newPasswordForm: string;
  newPasswordConfirmation: string;
  currentPassword: string;
};

const cn = classNames.bind(styles);

const UpdatePassword: React.FC = () => {
  const { isLoading, error, response, updatePassword } = useProfile();

  const { register, handleSubmit, errors, setValue } = useForm<Inputs>({
    validationSchema: passwordSchema,
  });

  const onSubmit = (data: Inputs) => {
    updatePassword(data).then((res: boolean | undefined) => {
      if (res) {
        setValue("newPasswordForm", "");
        setValue("newPasswordConfirmation", "");
        setValue("currentPassword", "");
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn("form")}>
      <SubTitle text="Update Password" />
      <p className={cn("form__message")}>
        <span className={cn("form__message-error")}>{error}</span>
        <span className={cn("form__message-response")}>{response}</span>
      </p>

      <Field
        register={register}
        error={errors.currentPassword}
        label="Old Password"
        id="currentPassword"
        type="password"
      />

      <Field
        register={register}
        error={errors.newPasswordForm}
        label="New Password"
        id="newPasswordForm"
        type="password"
      />

      <Field
        register={register}
        error={errors.newPasswordConfirmation}
        label="Confirm New Password"
        id="newPasswordConfirmation"
        type="password"
      />

      <div className={cn("buttons")}>
        <CancelBtn text="Cancel" type="reset" />
        <SaveBtn type="submit" text="Save" isLoading={isLoading} />
      </div>
    </form>
  );
};

export default UpdatePassword;
