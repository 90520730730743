import React from "react";
// components
import Quantity from "components/Quantity";
// styles
import styles from "../styles.module.scss";
import classNames from "classnames/bind";

interface ITRow {
  mealTime: string;
  categoryName?: string;
  value?: number;
  id: string;
  menu?: string;
  onChange?: ({ target }: { target: EventTarget | null }) => void;
  setValues?: (field: string, value: any, shouldValidate?: boolean) => void;
  unresponsive?: boolean;
  isPrintMenu?:boolean;
}

const cn = classNames.bind(styles);

const TRow: React.FC<ITRow> = ({
  mealTime,
  categoryName,
  value,
  id,
  onChange,
  menu,
  setValues,
  unresponsive = false,
  isPrintMenu
}) => {
  return (
    <div
      className={cn("trow", {
        trow_responsive: !unresponsive,
      })}
    >
      <span className={cn("trow-daily-menu")}>{mealTime}</span>
      <div className={cn("features", { features_responsive: !unresponsive })}>
        <span className={cn("table-qnt")}>
          <Quantity
            value={value}
            id={id}
            onChange={onChange}
            menu={menu}
            setValues={setValues}
            isPrintMenu={isPrintMenu}
          />
        </span>
        <span
          className={cn("table-category-name", "trow-category", {
            "table-category-name_responsive": !unresponsive,
          })}
        >
          {categoryName}
        </span>
      </div>
    </div>
  );
};

export default TRow;
