import React, { useMemo } from "react";
// hooks
import { useHolidays } from "hooks/useHolidays";
// components
import Day from "components/Select/Day";
import HolidayNote from "components/HolidayNote";
// utils
import { momentDate } from "./moment";
import { getDayNameMontn, isSameDate } from "utils/date";
import moment from "moment";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

interface IWeek {
  currentDate: Date | string;
  roomId?: number | string;
  weeklyOrders?: any[];
}

const cn = classNames.bind(styles);

const WeekDays: React.FC<IWeek> = ({ currentDate, roomId, weeklyOrders }) => {
  const { monday, tuesday, wednesday, thursday, friday, isSameDay } =
    momentDate(currentDate);

  const { isHoliday, holidays } = useHolidays();

  const checkStatus = (day: Date) =>
    weeklyOrders?.find((el) => isSameDay(new Date(el.orderDate), day));

  const days = [
    {
      date: monday,
      isActive: moment(monday).isSame(currentDate, "day"),
      status: checkStatus(monday)
        ? checkStatus(monday).status
        : "not scheduled",
    },
    {
      date: tuesday,
      isActive: moment(tuesday).isSame(currentDate, "day"),
      status: checkStatus(tuesday)
        ? checkStatus(tuesday).status
        : "not scheduled",
    },
    {
      date: wednesday,
      isActive: moment(wednesday).isSame(currentDate, "day"),
      status: checkStatus(wednesday)
        ? checkStatus(wednesday).status
        : "not scheduled",
    },
    {
      date: thursday,
      isActive: moment(thursday).isSame(currentDate, "day"),
      status: checkStatus(thursday)
        ? checkStatus(thursday).status
        : "not scheduled",
    },
    {
      date: friday,
      isActive: moment(friday).isSame(currentDate, "day"),
      status: checkStatus(friday)
        ? checkStatus(friday).status
        : "not scheduled",
    },
  ];

  const holidayNote = useMemo(() => {
    if (isHoliday(currentDate)) {
      return holidays.find(({ holidayDate }) =>
        isSameDate(holidayDate, currentDate)
      )?.note;
    }
  }, [currentDate, holidays, isHoliday]);

  return (
    <div className={cn("week-wrapper")}>
      <div className={cn("week")}>
        {days.map((day) => (
          <Day
            date={day.date}
            key={+day.date}
            status={day.status}
            roomId={roomId}
            isActive={day.isActive}
          />
        ))}
      </div>
      <span className={cn("day-now")}>
        <span className={cn("day-now__title")}>
          {getDayNameMontn(currentDate)}
        </span>
        {holidayNote && (
          <div className={cn("day-now__holiday-note")}>
            <HolidayNote note={holidayNote} />
          </div>
        )}
      </span>
    </div>
  );
};

export default WeekDays;
