import { TOrder, TOrderItems } from "pages/Customer/Order/type";

export const serializeOrderItems = (orderItems: TOrderItems[]) => {
  return orderItems.reduce(
    (acc, orderItem) => {
      const {
        quantity,
        dailyMenuItem: {
          mealTime: { id: mealTimeId, name: mealTimeName },
          menuItem: { id: menuItemId, name: menuItemName, categoryNames },
        },
      } = orderItem;

      acc.push({
        mealTimeId,
        mealTimeName,
        menuItemId,
        menuItemName,
        categoryNames,
        quantity,
      });

      return acc;
    },
    [] as {
      mealTimeId: string | number;
      mealTimeName: string;
      menuItemId: string | number;
      menuItemName: string;
      categoryNames: string;
      quantity: number;
    }[]
  );
};

export const serializeMenuItems = (menuItems: TOrder[]) => {
  return menuItems
    .map(({ orderItems, dailyMenu: { menuDate } }) =>
      serializeOrderItems(orderItems).map((orderItem) => ({
        ...orderItem,
        menuDate,
      }))
    )
    .flat();
};

export const serializeBeverages = (order: TOrder[]) =>
  order.map((el) => {
    const { menuDate } = el.dailyMenu;
    const result: any[] = [];

    if (!el?.orderBeverages?.length) {
      return [];
    }

    el?.orderBeverages?.map((beverage) => {
      result.push({
        id: beverage?.customerBeverage?.beverage?.id,
        date: menuDate,
        name: beverage?.customerBeverage?.beverage?.name,
        quantity: beverage?.quantity,
      });
    });

    return result;
  });
