import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "../styles.module.scss";
// icon
import okIcon from "assets/icons/ok.svg";

interface ISave {
  type?: "submit" | "button";
  onClick?: () => void;
  text: string;
  isLoading?: boolean;
}

const cn = classNames.bind(styles);

const Save: React.FC<ISave> = ({
  type = "button",
  onClick,
  text,
  isLoading,
}) => {
  return (
    <button type={type} onClick={onClick} className={cn("button", "save-btn")}>
      {text}
      <span className={cn("button-icon")}>
        {isLoading ? (
          <span className={cn("loader", "open")} />
        ) : (
          <img src={okIcon} alt="" className={cn("icons")} />
        )}
      </span>
    </button>
  );
};

export default Save;
