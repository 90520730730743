import React from "react";
// components
import LoginForm from "components/Forms/Login";
import AuthContainer from "components/Auth";

const Login: React.FC = () => {
  return (
    <AuthContainer title="Welcome">
      <LoginForm />
    </AuthContainer>
  );
};

export default Login;
