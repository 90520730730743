import { CUSTOMER_ROUTES } from "pages/routes";
import { useState } from "react";
// hooks
import { useRequest } from "hooks/useRequest";
import { useHistory } from "react-router-dom";
// redux
import { setUser, removeUser } from "store/reducers/user";
import { useDispatch } from "react-redux";
import { setClassroom, removeClassroom } from "store/reducers/classroom";
// routing
import { APP_ROUTES } from "pages/routes";

// utils
import {
  setLocalItem,
  removeLocalItem,
  setSessionItem,
  removeSessionItem,
  UUID,
} from "utils/storage";

export const useAuth = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { postRequest, error } = useRequest();

  const dispatch = useDispatch();
  const history = useHistory();

  const signIn = async (formData: any, url: string) => {
    try {
      setIsLoading(true);
      const result = await postRequest(url, formData);

      if (result) {
        const { data } = result;

        dispatch(setUser(data));

        const { rooms } = data.customer;

        if (rooms && rooms.length > 0) {
          dispatch(
            setClassroom({
              label: rooms[0].name,
              value: rooms[0].id,
            })
          );
        }

        formData.isRemember
          ? setLocalItem(UUID, data.token)
          : setSessionItem(UUID, data.token);

        history.push(CUSTOMER_ROUTES.ORDER);
      }

      setIsLoading(false);
    } catch (error) {
      console.error("sign in error");
      setIsLoading(false);
    }
  };

  const logout = async () => {
    try {
      setIsLoading(true);

      await removeLocalItem(UUID);
      await removeSessionItem(UUID);

      dispatch(removeUser());
      dispatch(removeClassroom());

      history.push(APP_ROUTES.AUTH);

      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setIsLoading(false);
    }
  };

  return {
    signIn,
    isLoading,
    error,
    logout,
  };
};
