import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "../../field-style.module.scss";

const cn = classNames.bind(styles);

interface IDisabled {
  value?: string;
}

const Disabled: React.FC<IDisabled> = ({ value }) => {
  return (
    <div className={cn("field", "simple", "disabled")}>
      <input
        type="text"
        className={cn("field__input-simple")}
        value={value ? value : ""}
        disabled
      />
    </div>
  );
};

export default Disabled;
