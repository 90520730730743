import React from "react";
// components
import Input from "components/Fields/Input/Formik";
// styles
import classNames from "classnames/bind";
import styles from "components/Forms/styles.module.scss";


interface ITextFormik {
  label: string;
  id?: string;
  onBlur?: ({ target }: { target: EventTarget | null }) => void;
  onChange?: ({target}: {target: EventTarget | null}) => void;
  values?: string;
  touched?: boolean;
  error?: string;
}

const cn = classNames.bind(styles);

const TextFormik: React.FC<ITextFormik> = ({ label, ...rest }) => {
  return (
    <div className={cn("form__item")}>
      <span className={cn("form__item--text")}>{label}</span>
      <div className={cn("form__item--field")}>
        <Input {...rest} label={label} />
      </div>
    </div>
  );
};

export default TextFormik;
