import React, { useEffect } from "react";
// hooks
import { useUser } from "hooks/useUser";
import { useHolidays } from "hooks/useHolidays";
// routing
import {
  Switch,
  Route,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import { APP_ROUTES } from "./routes";
// pages
import Auth from "pages/Auth";
import Customer from "pages/Customer";
import Invite from "pages/Invite";
import Restore from "pages/Restore";
import Loading from "components/Loader";
import NotFound from "pages/NotFound";
// styles
import "../styles/main.scss";

const App = () => {
  const { isLoading, checkUser } = useUser(true);
  const { fetchHolidays } = useHolidays();

  useEffect(() => {
    checkUser();
  }, [checkUser]);

  useEffect(() => {
    fetchHolidays();
  }, [fetchHolidays]);

  return (
    <Loading isLoading={isLoading}>
      <Router>
        <Switch>
          <Route path={APP_ROUTES.INVITE} component={Invite} />
          <Route path={APP_ROUTES.RESTORE} component={Restore} />
          <Route path={APP_ROUTES.AUTH} component={Auth} />
          <Route path={APP_ROUTES.CUSTOMER} component={Customer} />

          <Route path="/" exact>
            <Redirect to={APP_ROUTES.AUTH} />
          </Route>

          <Route to="*">
            <NotFound />
          </Route>
        </Switch>
      </Router>
    </Loading>
  );
};

export default App;
