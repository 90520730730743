import React from "react";
// components
import { FieldArray } from "formik";
import Input from "components/Fields/Input/Formik";
// styles
import classNames from "classnames/bind";
import styles from "components/Forms/styles.module.scss";

interface IClassroom {
  values?: any[];
  label: string;
  setFieldValue: any;
}

const cn = classNames.bind(styles);

const Classroom: React.FC<IClassroom> = ({ values, label, setFieldValue }) => {
  return (
    <FieldArray
      name="rooms"
      render={({ remove, push }) => (
        <div className={cn("form__item", "rooms")}>
          <span className={cn("form__item--text")}>{label}</span>
          {values && values.length > 0 ? (
            values.map((room, index) => (
              <div className={cn("form__item--field", "item-btn")} key={index}>
                <Input
                  label={room.name}
                  values={room.name}
                  disabled
                  onChange={(e: any) => {
                    setFieldValue(`rooms.${index}.name`, e.target.value);
                  }}
                />
              </div>
            ))
          ) : (
            <div className={cn("form__item--field", "item-btn")} />
          )}
        </div>
      )}
    />
  );
};

export default Classroom;
