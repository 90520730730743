import React, { useMemo, useEffect, useState } from "react";
// hooks
import { useOrder } from "hooks/useOrder";
import { useRoom } from "hooks/useRoom";
import { useHolidays } from "hooks/useHolidays";
// redux
import { useDispatch } from "react-redux";
import { setSelectedDate } from "store/reducers/selectedDate";
import { useHistory } from "react-router-dom";
// components
import AsideWrapper from "components/Aside/AsideWrapper";
import BackBtn from "components/Button/Back";
import Table from "components/Table";
import Month from "components/Select/Month";
import WeekDays from "components/Select/WeekDays";
import Loader from "components/Loader";
import Weeks from "components/Select/Weeks";
import Notification from "components/Notification";
import WeekRange from "components/WeekRange";
import SelectedClass from "components/SelectedClass";
// types
import { RouteComponentProps } from "react-router-dom";
import { TDailyMenu, TOrderInputs } from "./type";
// utils
import { getInputs } from "./serialize";
import { getNextDayUrl, getDayForUrl, isCanOrder } from "utils/date";
import momentTZ from "moment-timezone";
// routing
import { CUSTOMER_ROUTES } from "pages/routes";
import { getRoomUrl } from "utils/requests";
import PrintMenu from "../../../components/PrintMenu";

interface Props
  extends RouteComponentProps<
    {},
    any,
    { notificStatus?: string; notificDate?: Date | string | null }
  > {}

const Room = (props: Props) => {
  const { search, state } = props.location;
  const { push } = props.history;
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading: isHolidaysLoading } = useHolidays();

  const [daily, setDaily] = useState<null | TDailyMenu>(null);
  const [isEmptyForm, setIsEmptyForm] = useState<boolean>(false);

  const { isLoading, getOrder, saveOrder, skipOrder, error } = useOrder();
  const { setClassRoom } = useRoom();

  const menuDate = useMemo(
    () => new URLSearchParams(search).get("menuDate"),
    [search],
  );

  const roomId = useMemo(
    () => new URLSearchParams(search).get("roomId"),
    [search],
  );

  const onSubmit = async (inputs: TOrderInputs) => {
    if (daily && roomId && menuDate) {
      const { dailyMenuItems, orderBeverages } = getInputs(inputs);
      const nextDayUrl = getRoomUrl(getNextDayUrl(menuDate), roomId);

      if (dailyMenuItems.length === 0) {
        setIsEmptyForm(true);
        return null;
      }
      const specialRequirements = inputs.specialRequirements.filter((r) =>
        Object.values(r).some((i) => i),
      );

      const result = await saveOrder(
        {
          dailyMenuId: daily.id,
          roomId,
          status: "scheduled",
          dailyMenuItems,
          orderBeverages,
          specialRequirements,
          notes: inputs.notes ? inputs.notes : daily.order?.id ? "" : undefined,
        },
        daily.order?.id,
      );

      dailyMenuItems.length !== 0 &&
        result &&
        push(nextDayUrl, { notificStatus: "scheduled", notificDate: menuDate });
    }
  };

  const onSkip = async () => {
    if (daily && roomId && menuDate) {
      await skipOrder(
        {
          dailyMenuId: daily.id,
          roomId,
          status: "skipped",
        },
        daily.order?.id,
      );
      const nextDayUrl = getRoomUrl(getNextDayUrl(menuDate), roomId);

      push(nextDayUrl, { notificStatus: "skipped", notificDate: menuDate });
    }
  };

  const backToCalendar = () => {
    push(CUSTOMER_ROUTES.ORDER);
  };

  const onMonthClick = (monthDate: Date) => {
    if (roomId) {
      const nextMonthUrl = getRoomUrl(getDayForUrl(monthDate), roomId);
      dispatch(setSelectedDate(monthDate));

      push(nextMonthUrl);
    }
  };

  const onWeekClick = (week: Date) => {
    dispatch(setSelectedDate(week));
    roomId && history.push(getRoomUrl(getDayForUrl(week), roomId));
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    menuDate &&
      roomId &&
      getOrder(menuDate, roomId).then((res) => setDaily(res));

    roomId && setClassRoom(roomId);

    menuDate && dispatch(setSelectedDate(momentTZ(menuDate).toDate()));
    return () => {
      setIsEmptyForm(false);
    };
  }, [menuDate, roomId, getOrder, dispatch, setClassRoom]);

  return (
    <AsideWrapper>
      <Loader isLoading={isLoading || isHolidaysLoading}>
        {menuDate && roomId ? (
          <div className="container">
            {state?.notificStatus && state?.notificDate && (
              <Notification
                status={state.notificStatus}
                date={state.notificDate}
              />
            )}
            <div className="max-container">
              <div className="head-container">
                <BackBtn text="Back to calendar" onClick={backToCalendar} />
                <div className="head-container__stats">
                  <PrintMenu daily={daily} menuDate={menuDate} />
                </div>
              </div>

              <Month onClick={onMonthClick} />
              <Weeks date={new Date(menuDate)} onWeekClick={onWeekClick} />
              <SelectedClass />
              <WeekRange date={new Date(menuDate)} />

              <WeekDays
                currentDate={menuDate}
                roomId={roomId}
                weeklyOrders={daily?.weeklyOrders}
              />
              <span className="table-error">
                {error ? error : isEmptyForm && "Need select some food"}

                {!isCanOrder(menuDate) && "Sorry, You can't create order!"}
              </span>
              {daily && daily.dailyMenuToMenuItems ? (
                <Table
                  dailyMenus={daily.dailyMenuToMenuItems}
                  beverages={daily.customerBeverages}
                  submit={onSubmit}
                  onSkip={onSkip}
                  order={daily.order}
                  menuDate={menuDate}
                />
              ) : (
                <div className="without-menu">Menu Not Available</div>
              )}
            </div>
          </div>
        ) : (
          <div> url not correct</div>
        )}
      </Loader>
    </AsideWrapper>
  );
};

export default Room;
