import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "../../field-style.module.scss";

interface IFormikInput {
  id?: string;
  onBlur?: ({ target }: { target: EventTarget | null }) => void;
  onChange?: ({ target }: React.ChangeEvent<HTMLInputElement>) => void;
  values?: string;
  touched?: boolean;
  error?: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  wrapStyle?: string;
  inputStyle?: string;
  type?: string
  step?: string
}

const cn = classNames.bind(styles);

const FormikInput: React.FC<IFormikInput> = ({
  id,
  onBlur,
  onChange,
  values,
  touched,
  error,
  label,
  placeholder,
  disabled,
  wrapStyle,
  inputStyle,
  step,
  type = "text",
}) => {
  return (
    <div className={cn("input-wrapper-simple", wrapStyle)}>
      <div
        className={cn(
          "field",
          "simple",
          error && "field-error",
          disabled && "disabled"
        )}
      >
        <input
          id={id}
          className={cn("field__input-simple", inputStyle)}
          name={id}
          type={type}
          step={step}
          onBlur={onBlur}
          onChange={onChange}
          value={values}
          placeholder={label ? label : placeholder}
          disabled={disabled && disabled}
        />
      </div>
      {error && (
        <span className={cn("field__error-simple")}>
          {error && touched && error}
        </span>
      )}
    </div>
  );
};

export default FormikInput;
