import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "../styles.module.scss";

interface IAddBtn {
  onClick?: () => void;
  text: string;
  type?: "submit" | "button";
  style?: string;
}

const cn = classNames.bind(styles);

const AddBtn: React.FC<IAddBtn> = ({ onClick, text, style, type = "button" }) => {
  return (
    <button onClick={onClick} type={type} className={cn("button", "circle-btn", style)}>
      {text}
    </button>
  );
};

export default AddBtn;
