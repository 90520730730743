import React, { useMemo } from "react";
// components
import THead from "./THeader";
import TRow from "./TRow";
import Notes from "components/Notes";
import SpecialRequirements from "components/SpecialRequirements";
import Save from "components/Button/Save";
import Skip from "components/Button/Cancel";
import { Formik } from "formik";
// styles
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
// utils
import {
  serializeDailyMenu,
  TSerializedDailyMenu,
  serializeDailyMenuFormik,
  serializeDefaultValueBeverages,
  serializeDefaultValue,
  serializeBeverages,
  serializeDailyValue,
} from "./serialize";
import { isCanOrder } from "utils/date";
// types
import {
  TDailyMenuToMenuItems,
  TOrderInputs,
  TBeverages,
} from "pages/Customer/Room/type";
import { TOrder } from "pages/Customer/Order/type";
import THeader from "./THeader";

interface ITable {
  dailyMenus?: TDailyMenuToMenuItems[];
  beverages?: TBeverages[];
  submit?: (arg_0: TOrderInputs) => void;
  onSkip?: () => void;
  isLoading?: boolean;
  order: TOrder | null;
  menuDate: string | Date;
  isPrintMenu?: boolean;
}

const cn = classNames.bind(styles);

const Table: React.FC<ITable> = ({
  dailyMenus,
  beverages,
  submit,
  isLoading,
  onSkip,
  order,
  menuDate,
  isPrintMenu,
}) => {
  const menu: TSerializedDailyMenu[] | undefined = useMemo(
    () =>
      dailyMenus &&
      serializeDailyMenu(dailyMenus).sort((a, b) => (a.name > b.name ? 1 : -1)),
    [dailyMenus],
  );

  const initialValues = useMemo(
    () => ({
      dailyMenu:
        menu &&
        serializeDailyMenuFormik(menu, order && serializeDefaultValue(order)),
      beverages:
        beverages &&
        serializeBeverages(
          beverages,
          order &&
            order?.orderBeverages &&
            serializeDefaultValueBeverages(order),
        ),
      specialRequirements: order?.specialRequirements.length
        ? order.specialRequirements
        : [
            {
              menuItemsRequirements: "",
              allergiesRequirements: "",
              portionsRequirements: "",
            },
          ],
      notes: order?.notes || "",
    }),
    [order, beverages, menu],
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: any) => {
        if (submit) {
          submit(values);
        }
      }}
      enableReinitialize
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => (
        <form
          onSubmit={handleSubmit}
          className={cn("table", !isCanOrder(menuDate) && "fixed")}
        >
          {/* { DAILY MENUS} */}

          {menu &&
            menu.map((el: TSerializedDailyMenu, index) => (
              <div key={index}>
                {isPrintMenu &&
                menu &&
                order &&
                serializeDailyValue(menu, order, el.mealTimeId)
                  ?.filter((item) => item.mealTimeId === el.mealTimeId)
                  ?.map((order) => order.quantity)
                  ?.reduce(
                    (acc: number, currentValue) =>
                      Number(acc) + Number(currentValue),
                    0,
                  ) > 0 ? (
                  <THeader
                    dailyMenuName={el.name}
                    quantity={
                      isPrintMenu && menu && order
                        ? serializeDailyValue(menu, order, el.mealTimeId)
                            ?.filter(
                              (item) => item.mealTimeId === el.mealTimeId,
                            )
                            ?.map((order) => order.quantity)
                            .reduce(
                              (acc: number, currentValue) =>
                                Number(acc) + Number(currentValue),
                              0,
                            )
                        : undefined
                    }
                    unresponsive
                  />
                ) : null}
                {!isPrintMenu && <THead dailyMenuName={el.name} />}

                {el.menuItem.map(
                  ({ name, categoryNames, dailyMenuToMenuItemId }) =>
                    isPrintMenu ? (
                      values.dailyMenu[`input${dailyMenuToMenuItemId}`] > 0 ? (
                        <TRow
                          mealTime={name}
                          categoryName={categoryNames}
                          key={dailyMenuToMenuItemId}
                          id={`input${dailyMenuToMenuItemId}`}
                          menu="dailyMenu"
                          onChange={handleChange}
                          value={
                            values.dailyMenu[`input${dailyMenuToMenuItemId}`]
                          }
                          setValues={setFieldValue}
                          isPrintMenu={isPrintMenu}
                          unresponsive={isPrintMenu}
                        />
                      ) : null
                    ) : (
                      <TRow
                        mealTime={name}
                        categoryName={categoryNames}
                        key={dailyMenuToMenuItemId}
                        id={`input${dailyMenuToMenuItemId}`}
                        menu="dailyMenu"
                        onChange={handleChange}
                        value={
                          values.dailyMenu[`input${dailyMenuToMenuItemId}`]
                        }
                        setValues={setFieldValue}
                        isPrintMenu={isPrintMenu}
                        unresponsive={isPrintMenu}
                      />
                    ),
                )}
              </div>
            ))}
          {/* { BEVERAGES} */}

          {menu && beverages && (
            <>
              {isPrintMenu ? (
                <THeader
                  dailyMenuName="Beverages"
                  quantity={
                    isPrintMenu && order
                      ? serializeDefaultValueBeverages(order)
                          ?.map((elem) => elem.quantity)
                          ?.reduce(
                            (acc: number, currentValue) =>
                              Number(acc) + Number(currentValue),
                            0,
                          )
                      : undefined
                  }
                  unresponsive
                />
              ) : (
                <THeader dailyMenuName="Beverages" />
              )}
              {beverages.map(({ customerBeverageId, name }) =>
                isPrintMenu ? (
                  values.beverages[`input${customerBeverageId}`] > 0 ? (
                    <TRow
                      mealTime={name}
                      key={customerBeverageId}
                      id={`input${customerBeverageId}`}
                      menu="beverages"
                      onChange={handleChange}
                      value={values.beverages[`input${customerBeverageId}`]}
                      setValues={setFieldValue}
                      isPrintMenu={isPrintMenu}
                      unresponsive={isPrintMenu}
                    />
                  ) : null
                ) : (
                  <TRow
                    mealTime={name}
                    key={customerBeverageId}
                    id={`input${customerBeverageId}`}
                    menu="beverages"
                    onChange={handleChange}
                    value={values.beverages[`input${customerBeverageId}`]}
                    setValues={setFieldValue}
                    isPrintMenu={isPrintMenu}
                    unresponsive={isPrintMenu}
                  />
                ),
              )}
            </>
          )}

          {!isPrintMenu && (
            <div className={cn("specials")}>
              <Notes
                id="notes"
                styleWrap={cn("specials__notes")}
                onChange={handleChange}
                value={values.notes}
              />
              <SpecialRequirements
                styleWrap={cn("specials__requirements")}
                onChange={handleChange}
                setValues={setFieldValue}
                value={values.specialRequirements}
              />
            </div>
          )}

          {isCanOrder(menuDate) && !isPrintMenu && (
            <div className={cn("btn-wrapper")}>
              <Skip text="Skip Day" onClick={onSkip} />
              <Save text="Save" type="submit" isLoading={isLoading} />
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default Table;
