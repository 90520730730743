import React from "react";
// assets
import { ReactComponent as CalendarStarIcon } from "assets/icons/calendar-star.svg";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

interface IHolidayNote {
  note?: string;
}

const cn = classNames.bind(styles);

const HolidayNote: React.FC<IHolidayNote> = ({ note }) => {
  return (
    <div className={cn("calendar-note")}>
      <CalendarStarIcon className={cn("calendar-note__icon")} />
      <span className={cn("calendar-note__text")}>{note}</span>
    </div>
  );
};

export default HolidayNote;
