import React from "react";
// components
import THeader from "components/Table/THeader";
import TRow from "components/Table/TRow";
// types
import { TBeverageSum, TMonthlyMealTimes } from "../type";

interface IItemsList {
  monthlyMealTimes?: TMonthlyMealTimes[];
  beveragesSum?: TBeverageSum[];
  unresponsive?: boolean;
}

const ItemsList: React.FC<IItemsList> = ({
  monthlyMealTimes = [],
  beveragesSum = [],
  unresponsive = false,
}) => {
  return (
    <div>
      {monthlyMealTimes?.map(({ id, name, orderItems }) => {
        const mealTimeQuantity = orderItems.reduce((acc, { quantity }) => {
          acc += quantity;
          return acc;
        }, 0);

        return (
          <div key={id}>
            <THeader
              dailyMenuName={name}
              unresponsive={unresponsive}
              quantity={mealTimeQuantity}
            />
            {orderItems.map(({ id, name, quantity, categoryNames }) => (
              <TRow
                key={id}
                id={id.toString()}
                mealTime={name}
                value={quantity}
                categoryName={categoryNames}
                unresponsive={unresponsive}
              />
            ))}
          </div>
        );
      })}

      {!!beveragesSum?.length && (
        <THeader
          dailyMenuName="Beverages"
          unresponsive={unresponsive}
          quantity={beveragesSum.reduce((acc, { quantity }) => {
            acc += quantity;
            return acc;
          }, 0)}
        />
      )}

      {beveragesSum?.map(({ name, quantity, id }) => (
        <TRow
          key={id}
          id={id.toString()}
          mealTime={name}
          value={quantity}
          unresponsive={unresponsive}
        />
      ))}
    </div>
  );
};

export default ItemsList;
