import React, { useCallback, useMemo, useRef, useState } from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// redux
import { useSelector } from "react-redux";
import { selectDate } from "store/reducers/selectedDate/selectors";
import { selectClassroom } from "store/reducers/classroom/selectors";
// components
import Button from "components/Button";
import SecondaryBtn from "components/Button/Secondary";
import Dialog from "components/Dialog";
import SelectClassroom from "components/SelectClassroom";
import ItemsList from "./ItemsList";
import ReactToPrint from "react-to-print";
// utils
import { serializeBeverages, serializeMenuItems } from "./serializers";
import { getMonthYear } from "utils/date";
// types
import { TOrder } from "pages/Customer/Order/type";
import { TBeverageSum, TMonthlyMealTimes } from "./type";
// assets
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";

interface IStats {
  menuItems: TOrder[] | null;
}

const cn = classNames.bind(styles);

const Stats: React.FC<IStats> = ({ menuItems }) => {
  const { date } = useSelector(selectDate);
  const classroom = useSelector(selectClassroom);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const pdfExportComponent = useRef<HTMLDivElement>(null);

  const { items, beverages } = useMemo(
    () => ({
      items: menuItems && serializeMenuItems(menuItems),
      beverages: menuItems && serializeBeverages(menuItems).flat(),
    }),
    [menuItems]
  );

  const beveragesSum = useMemo(() => {
    return beverages?.reduce<TBeverageSum[]>((acc, el) => {
      const currentBeverage = acc.find(({ id }) => id === el.id);

      if (currentBeverage) {
        currentBeverage.quantity += el.quantity;
      } else {
        acc.push({
          id: el.id,
          name: el.name,
          quantity: el.quantity,
        });
      }

      return acc;
    }, []);
  }, [beverages]);

  const monthlyMealTimes = useMemo(() => {
    return items?.reduce<TMonthlyMealTimes[]>((acc, item) => {
      const mealTime = acc.find(
        (accItem: any) => accItem.id === item.mealTimeId
      );

      if (!mealTime) {
        acc.push({
          id: item.mealTimeId,
          name: item.mealTimeName,
          orderItems: [
            {
              id: item.menuItemId,
              name: item.menuItemName,
              quantity: item.quantity,
              categoryNames: item.categoryNames,
            },
          ],
        });
      } else {
        const orderItem = mealTime.orderItems.find(
          (orderItem) => orderItem.id === item.menuItemId
        );

        if (!orderItem) {
          mealTime.orderItems.push({
            id: item.menuItemId,
            name: item.menuItemName,
            quantity: item.quantity,
            categoryNames: item.categoryNames,
          });
        } else {
          orderItem.quantity += item.quantity;
        }
      }

      return acc;
    }, []);
  }, [items]);

  const handleDialogClose = useCallback(() => setDialogIsOpen(false), []);

  return (
    <>
      <Button
        text="Stats"
        variant="rounded"
        onClick={() => setDialogIsOpen(true)}
      />
      <Dialog isOpen={dialogIsOpen} handleClose={handleDialogClose}>
        <div className={cn("modal-content")}>
          <CloseIcon
            className={cn("modal-content__close")}
            onClick={handleDialogClose}
          />

          <h1 className={cn("month-title")}>{getMonthYear(date)}</h1>

          <div className={cn("modal-content__actions")}>
            <SelectClassroom />

            <ReactToPrint
              trigger={() => {
                return <Button text="PRINT / PDF" variant="square" />;
              }}
              content={() => pdfExportComponent.current}
            />
          </div>

          <div className={cn("modal-content__list")}>
            <ItemsList
              monthlyMealTimes={monthlyMealTimes}
              beveragesSum={beveragesSum}
            />
          </div>
        </div>
        <div className={cn("pdf-export-container")}>
          <div ref={pdfExportComponent}>
            <h1 className={cn("month-title")}>{getMonthYear(date)}</h1>

            <div>Classroom: {classroom.label}</div>

            <ItemsList
              monthlyMealTimes={monthlyMealTimes}
              beveragesSum={beveragesSum}
              unresponsive
            />
          </div>
        </div>
        <div className={cn("modal-actions")}>
          <SecondaryBtn
            text="Back"
            onClick={() => {
              setDialogIsOpen(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default Stats;
