import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// image
import logoIcon from "assets/images/logo-mini.svg";

interface IHeader {
  onClick?: () => void;
  isOpen?: boolean;
  isHideBurger?: boolean;
}

const cn = classNames.bind(styles);

const Header: React.FC<IHeader> = ({
  onClick,
  isOpen,
  isHideBurger = false,
}) => {
  return (
    <header className={cn("header", isOpen && "open-header")}>
      <span
        className={cn(
          "header__burger",
          isOpen && "open-burder",
          isHideBurger && "hide-burder"
        )}
        onClick={onClick}
      />
      <div className={cn("header__logo")}>
        <img src={logoIcon} alt="Beyond green" />
      </div>
    </header>
  );
};

export default Header;
