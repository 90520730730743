import { RootState } from "store/rootReducer";
import { TUser } from "store/reducers/user/type";

export const selectToken = (state: RootState): string | null =>
  state.user.token;

export const selectUser = (state: RootState): TUser => state.user;

export const selectName = (state: RootState): string | undefined =>
  state.user.customer?.name;

export const selectEmail = (state: RootState): string | undefined =>
  state.user.customer?.email;
