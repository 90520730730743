import React from "react";
// hooks
import { useForm } from "react-hook-form";
// components
import Input from "components/Fields/Input/Label";
import Button from "components/Button";
// styles
import classNames from "classnames/bind";
import styles from "../forms-styles.module.scss";
// utils
import { inviteSchema } from "utils/validation";

export type Inputs = {
  password: string;
  repeatPassword: string;
};

export type TNewPassword = {
  token: string | null;
  email: string | null;
  password: string;
  passwordConfirmation: string;
};

interface INewPassword {
  isLoading?: boolean;
  error?: string | null;
  onSubmit: (arg_0: Inputs) => void;
}

const cn = classNames.bind(styles);

const NewPassword: React.FC<INewPassword> = ({
  isLoading,
  error,
  onSubmit,
}) => {
  const { register, handleSubmit, errors } = useForm<Inputs>({
    validationSchema: inviteSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn("form")}>
      {error && <span className={cn("form__error")}>{error}</span>}
      <Input
        id="password"
        label="Password"
        type="password"
        icon="lock"
        myRef={register}
        error={errors.password}
      />

      <Input
        id="repeatPassword"
        label="Repeat Password"
        type="password"
        icon="lock"
        myRef={register}
        error={errors.repeatPassword}
      />

      <div className={cn("form__btn")}>
        <Button
          isSubmit
          text="Set Password"
          isLoading={isLoading}
          variant="fullWidth"
        />
      </div>
    </form>
  );
};

export default NewPassword;
