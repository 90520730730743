import React, { useRef, useMemo, useEffect } from "react";
// hooks
import { useWindowDimensions } from "hooks/useWindowDimensions";
// calendar
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
// redux
import { useSelector } from "react-redux";
import { selectDate } from "store/reducers/selectedDate/selectors";
// components
import DayHeader from "./DayHeader";
// utils
import {
  getMenuItemToCalendar,
  serializeMenuToCalendar,
  serializeBeveragesToCalendar,
  getItemsToCalendar,
} from "./serialized";
import moment from "moment";
// styles
import "./styles.scss";
// types
import { TOrder } from "pages/Customer/Order/type";

interface ICalendar {
  dayClick: (arg_0: Date | string) => void;
  menuItems: TOrder[] | null;
}

const Calendar: React.FC<ICalendar> = ({ dayClick, menuItems }) => {
  const componentRef = useRef<any>(null);
  const selectedDate = useSelector(selectDate);

  const { width } = useWindowDimensions();

  const isBigScreen = width > 900;

  const _events = useMemo(() => menuItems && getMenuItemToCalendar(menuItems), [
    menuItems,
  ]);

  const items = menuItems && serializeMenuToCalendar(menuItems);
  const beverages = menuItems && serializeBeveragesToCalendar(menuItems).flat();

  const event = items && [
    ...getItemsToCalendar(items).flat(),
    ...(beverages || []),
  ];

  const handleDateClick = (arg: any) => {
    dayClick(arg.dateStr);
  };

  const calendarOption = {
    displayEventTime: false,
    eventBackgroundColor: "transparent",
    eventTextColor: "#000",
    eventBorderColor: "transparent",
    fixedWeekCount: false,
    hiddenDays: [0],
    weekends: false,
    dayMaxEvents: true,
    aspectRatio: 1.2,
    headerToolbar: { left: "prev", center: "title", right: "next" },
    eventOrder: "start",
  };

  const dayCellContent = (arg: any) => {
    const date = moment(arg.date);

    const event =
      _events &&
      _events.find((el) => {
        return moment(el.date).isSame(date, "day");
      });

    return <DayHeader date={arg.date} status={event?.status} />;
  };

  useEffect(() => {
    const moveToDate = componentRef?.current?.getApi();
    moveToDate && moveToDate.gotoDate(selectedDate.date);
  }, [selectedDate]);

  return (
    <div className="calendar">
      <FullCalendar
        ref={componentRef}
        plugins={[dayGridPlugin, interactionPlugin]}
        dateClick={handleDateClick}
        events={event ? event : {}}
        {...calendarOption}
        dayCellContent={dayCellContent}
        headerToolbar={{ left: "prev", center: "title", right: "next" }}
        initialDate={selectedDate.date || +new Date()}
        initialView={isBigScreen ? "dayGridMonth" : "dayGridWeek"}
      />
    </div>
  );
};

export default Calendar;
