import React from "react";
// hooks
import { useAuth } from "hooks/useAuth";
// routing
import { CUSTOMER_ROUTES } from "pages/routes";
import { useHistory, useLocation } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
import { selectName } from "store/reducers/user/selectors";
// components
import CopyRight from "components/CopyRight";
import MenuItem from "components/Aside/MenuItem";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// image
import orderIcon from "assets/images/aside/order.svg";
import profileIcon from "assets/images/aside/profile.svg";
import logoutIcon from "assets/images/aside/logout.svg";

interface IAside {
  isOpen: boolean;
}

const cn = classNames.bind(styles);

const Aside: React.FC<IAside> = ({ isOpen }) => {
  const name = useSelector(selectName);
  const history = useHistory();
  const location = useLocation();
  const { logout } = useAuth();

  const orderClick = () => history.push(CUSTOMER_ROUTES.ORDER);
  const profileClick = () => history.push(CUSTOMER_ROUTES.PROFILE);

  return (
    <aside className={cn("aside", isOpen && "open-aside")}>
      <div className={cn("aside__content")}>
        <p className={cn("aside__user-name")}>{name}</p>
        <nav className={cn("aside__navigation")}>
          <ul className={cn("aside__menu")}>
            <MenuItem
              icon={orderIcon}
              title="Order"
              onClick={orderClick}
              isActive={location.pathname !== CUSTOMER_ROUTES.PROFILE}
            />
            <MenuItem
              icon={profileIcon}
              title="Profile"
              onClick={profileClick}
              isActive={location.pathname === CUSTOMER_ROUTES.PROFILE}
            />
            <MenuItem icon={logoutIcon} title="Log out" onClick={logout} />
          </ul>
        </nav>
      </div>
      <CopyRight />
    </aside>
  );
};

export default Aside;
