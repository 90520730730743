import { useState } from "react";
// hooks
import { useSelector } from "react-redux";
import { useRequest } from "hooks/useRequest";
// redux
import { selectToken } from "store/reducers/user/selectors";
import { useDispatch } from "react-redux";
import { setCustomer } from "store/reducers/user";
import { setClassroom } from "store/reducers/classroom";

// api
import { urlPostProfileUpdate, urlPostPasswordChange } from "utils/requests";
// types
import { Inputs as InputPassword } from "components/Forms/UpdatePassword";

export const useProfile = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [response, setResponse] = useState<string | null>(null);
  const { postRequest, error } = useRequest();

  const dispatch = useDispatch();

  const token = useSelector(selectToken);

  const updateProfile = async (data: any) => {
    try {
      setIsLoading(true);
      setResponse(null);

      const result = await postRequest(urlPostProfileUpdate, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result) {
        const { data } = result;

        dispatch(setCustomer(data));

        const { rooms } = data;

        setResponse("Profile was changed");

        rooms && rooms.length > 0
          ? dispatch(
              setClassroom({
                label: rooms[0].name,
                value: rooms[0].id,
              })
            )
          : dispatch(setClassroom(null));

        setIsLoading(false);
        return true;
      }

      setIsLoading(false);
      return false;
    } catch (e) {
      setIsLoading(false);
    }
  };

  const updatePassword = async (data: InputPassword) => {
    try {
      setIsLoading(true);
      setResponse(null);

      const {
        newPasswordForm,
        newPasswordConfirmation,
        currentPassword,
      } = data;

      const result = await postRequest(
        urlPostPasswordChange,
        {
          currentPassword,
          password: newPasswordForm,
          passwordConfirmation: newPasswordConfirmation,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (result) {
        const { data } = result;

        dispatch(setCustomer(data));
        setResponse("Password was changed");
        setIsLoading(false);
        return true;
      }

      setIsLoading(false);
      return false;
    } catch (e) {}
  };

  return { isLoading, error, response, updateProfile, updatePassword };
};
