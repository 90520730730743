import React, { useCallback, MouseEvent, useEffect } from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

interface IDialog {
  isOpen: boolean;
  handleClose?: () => void;
  children: React.ReactNode;
}

const cn = classNames.bind(styles);

const Dialog: React.FC<IDialog> = ({
  isOpen,
  handleClose = () => {},
  children,
}) => {
  const handleDialogContentClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => e.stopPropagation(),
    []
  );

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    <div
      className={cn("dialog-wrapper", { dialog_open: isOpen })}
      onClick={handleClose}
    >
      <div className={cn("dialog")} onClick={handleDialogContentClick}>
        {children}
      </div>
    </div>
  );
};

export default Dialog;
