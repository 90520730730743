// local storage
export const getLocalItem = (key: string) => localStorage.getItem(key);
export const setLocalItem = (key: string, data: any) =>
  localStorage.setItem(key, data);
export const removeLocalItem = (key: string) => localStorage.removeItem(key);

// session storage
export const getSessionItem = (key: string) => sessionStorage.getItem(key);
export const setSessionItem = (key: string, data: any) =>
  sessionStorage.setItem(key, data);
export const removeSessionItem = (key: string) =>
  sessionStorage.removeItem(key);

export const UUID = "uuid";
