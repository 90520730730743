import React from "react";
// components
import Text from "components/Forms/Item/TextFormik";
import { Formik } from "formik";
import SaveBtn from "components/Button/Save";
import CancelBtn from "components/Button/Cancel";
import Classrooms from "components/Forms/Item/ClassRoom";
import SubTitle from "components/SubTitle";
// hooks
import { useSelector } from "react-redux";
import { useProfile } from "hooks/useProfile";
// selector
import { selectUser } from "store/reducers/user/selectors";
// styles
import classNames from "classnames/bind";
import styles from "components/Forms/styles.module.scss";

const cn = classNames.bind(styles);

const UpdateProfile = () => {
  const { customer } = useSelector(selectUser);

  const { isLoading, error, response, updateProfile } = useProfile();

  return (
    <Formik
      initialValues={{
        name: customer?.name,
        companyName: customer?.companyName,
        address: customer?.address,
        city: customer?.city,
        state: customer?.state,
        zip: customer?.zip,
        rooms: customer?.rooms,
      }}
      onSubmit={(values: any) => updateProfile(values)}
      render={({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} className={cn("form")}>
          <SubTitle text="Update Profile" />

          <p className={cn("form__message")}>
            <span className={cn("form__message-error")}>{error}</span>
            <span className={cn("form__message-response")}>{response}</span>
          </p>
          <Text
            id="name"
            values={values.name}
            error={errors.name}
            label="School name"
            touched={touched.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Text
            id="companyName"
            values={values.companyName}
            error={errors.companyName}
            label="User Name"
            touched={touched.companyName}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Text
            id="address"
            values={values.address}
            error={errors.address}
            label="Address"
            touched={touched.address}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Text
            id="city"
            values={values.city}
            error={errors.city}
            label="City"
            touched={touched.city}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Text
            id="state"
            values={values.state}
            error={errors.state}
            label="State"
            touched={touched.state}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <Text
            id="zip"
            values={values.zip}
            error={errors.zip}
            label="Zip"
            touched={touched.zip}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          {values.rooms?.length ? (
            <Classrooms
              label="Classroom Name"
              values={values.rooms}
              setFieldValue={setFieldValue}
            />
          ) : null}

          <div className={cn("buttons")}>
            <CancelBtn text="Cancel" />
            <SaveBtn type="submit" text="Save" isLoading={isLoading} />
          </div>
        </form>
      )}
    />
  );
};

export default UpdateProfile;
