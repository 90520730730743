import { TOrder } from "pages/Customer/Order/type.d";
import {
  TDailyMenuToMenuItems,
  TDate,
  TDailyMenuItemsSerialized,
  TBeveragesSerialized,
  TBeverages,
} from "pages/Customer/Room/type";

export type TSerializedDailyMenu = {
  name: string;
  menuItem: {
    componentsCount: number | string;
    createdAt: TDate;
    deletedAt: TDate | null;
    id: string | number;
    name: string;
    categoryNames: string;
    dailyMenuToMenuItemId: string;
    mealTimeId: string | number;
  }[];
  id: number;
  mealTimeId: string | number;
};

export type TDailyMenuInputs = {
  [key: string]: number;
};

export const serializeDailyMenu: (
  data: TDailyMenuToMenuItems[],
) => TSerializedDailyMenu[] = (data) => {
  const result: any = {};

  data.forEach((element) => {
    const { dailyMenuToMenuItemId } = element;
    const { id, name } = element.mealTime;

    if (result[id]) {
      result[id].menuItem.push({
        ...element.menuItem,
        dailyMenuToMenuItemId,
        mealTimeId: element.mealTimeId,
      });
    } else {
      result[id] = {
        name,
        menuItem: [],
        mealTimeId: element.mealTimeId,
      };
      result[id].menuItem.push({
        ...element.menuItem,
        dailyMenuToMenuItemId,
        mealTimeId: element.mealTimeId,
      });
    }
  });

  return Object.values(result);
};

export const serializeDailyMenuFormik: (
  data: TSerializedDailyMenu[],
  defaultValues: TDailyMenuItemsSerialized[] | null,
) => any = (data, defaultValues) => {
  const result: TDailyMenuInputs = {};

  data.forEach((el) =>
    el.menuItem.forEach((item) => {
      const quantity =
        defaultValues &&
        defaultValues.find(
          (defaultItem) =>
            defaultItem.dailyMenuItemId === item.dailyMenuToMenuItemId,
        );

      result[`input${item.dailyMenuToMenuItemId}`] = quantity
        ? +quantity.quantity
        : 0;
    }),
  );

  return result;
};

export const serializeBeverages: (
  data: TBeverages[],
  defaultValues?: TBeveragesSerialized[] | null,
) => any = (data, defaultValues) => {
  const result: TDailyMenuInputs = {};

  data.forEach((beverage) => {
    const quantity =
      defaultValues &&
      defaultValues.find(
        (defaultItem) => defaultItem.beverageId === beverage.beverageId,
      );
    result[`input${beverage.customerBeverageId}`] = quantity
      ? +quantity.quantity
      : 0;
  });
  return result;
};

export const serializeDefaultValue: (
  data: TOrder,
) => TDailyMenuItemsSerialized[] = (data) =>
  data.orderItems.map(({ dailyMenuItemId, quantity }) => ({
    dailyMenuItemId,
    quantity,
  }));

export const serializeDailyValue: (
  dataMenus: TSerializedDailyMenu[],
  data: TOrder,
  mealTimeId: number | string,
) => TDailyMenuItemsSerialized[] = (dataMenus, data) => {
  const serializedMenu = dataMenus.map((item) => ({
    elements: item.menuItem,
    mealTimeId: item.mealTimeId,
    name: item.name,
  }));

  return data.orderItems.map(({ dailyMenuItemId, quantity }) => ({
    dailyMenuItemId,
    quantity,
    mealTimeId: serializedMenu?.find(
      (el) =>
        el.elements?.find((it) => it.dailyMenuToMenuItemId === dailyMenuItemId),
    )?.mealTimeId,
  }));
};

export const serializeDefaultValueBeverages: (
  data: TOrder,
) => TBeveragesSerialized[] | null = ({ orderBeverages }) => {
  return orderBeverages
    ? orderBeverages.map(
        ({ customerBeverageId, quantity, customerBeverage }) => ({
          customerBeverageId,
          quantity,
          beverageId: customerBeverage.beverageId,
        }),
      )
    : null;
};
