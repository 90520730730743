import { TOrder, TSerializedMenuItems } from "pages/Customer/Order/type";

export const getMenuItemToCalendar = (menuItems: TOrder[]) => {
  return menuItems.map((menu) => {
    return {
      date: `${menu.dailyMenu.menuDate}`,
      status: menu.status,
      mealTimes:
        menu.status === "scheduled"
          ? menu.orderItems.map((item) => item.dailyMenuItem.menuItem.name)
          : [],
    };
  });
};

export const serializeMenuToCalendar: (menuItems: TOrder[]) => any[] = (
  menuItems
) => {
  return menuItems.map((menu) => {
    const result: any = {};
    const date = menu.dailyMenu.menuDate;
    const status = menu.status;

    menu.orderItems.forEach((element) => {
      const { dailyMenuToMenuItemId } = element.dailyMenuItem;
      const { id, name } = element.dailyMenuItem.mealTime;

      if (result[id]) {
        result[id].menuItem.push({
          ...element.dailyMenuItem.menuItem,
          quantity: element.quantity,
          dailyMenuToMenuItemId,
        });
      } else {
        result[id] = {
          name,
          menuItem: [],
        };
        result[id].menuItem.push({
          ...element.dailyMenuItem.menuItem,
          quantity: element.quantity,
          dailyMenuToMenuItemId,
        });
      }
    });

    return {
      date,
      status,
      result: Object.values(result).sort((a: any, b: any) =>
        a.name > b.name ? 1 : -1
      ),
    };
  });
};

export const getItemsToCalendar = (menuItem: TSerializedMenuItems[]) => {
  return menuItem.map((item) => {
    const result: any[] = [];

    Object.values(item.result).forEach((el: any) => {
      const { date } = item;

      result.push({ date, title: el.name, className: "event title-event" });
      el.menuItem.forEach((element: any) => {
        result.push({
          date,
          title: `${element.name} x${element.quantity}`,
          className: "event",
        });
      });
    });

    return result;
  });
};

export const serializeBeveragesToCalendar = (order: TOrder[]) =>
  order.map((el) => {
    const { menuDate } = el.dailyMenu;
    const result: any[] = [];

    if (!el?.orderBeverages?.length) {
      return [];
    }

    result.push({
      date: menuDate,
      className: "event title-event",
      title: "Beverages",
    });

    el?.orderBeverages?.map((beverage) => {
      result.push({
        date: menuDate,
        title: `${beverage?.customerBeverage?.beverage?.name} x${beverage?.quantity}`,
        className: "event",
      });
    });

    return result;
  });
