import { combineReducers } from "redux";
import user from "store/reducers/user";
import classroom from "store/reducers/classroom";
import selectedDate from "store/reducers/selectedDate";
import holidays from "store/reducers/holidays";

export const rootReducer = combineReducers({
  user,
  classroom,
  selectedDate,
  holidays,
});

export type RootState = ReturnType<typeof rootReducer>;
