import React, { useState } from "react";
// hooks
import { useForm } from "react-hook-form";
import { useUser } from "hooks/useUser";
// components
import Input from "components/Fields/Input/Label";
import Button from "components/Button";
// styles
import classNames from "classnames/bind";
import styles from "../forms-styles.module.scss";
// utils
import { resetPassword } from "utils/validation";

export type Inputs = {
  email: string;
};

const cn = classNames.bind(styles);

const ResetPassword: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<Inputs>({
    validationSchema: resetPassword,
  });

  const [isShowMessage, setIsShowMessage] = useState(false);
  const { isLoading, passwordRecovery, error } = useUser();

  const onSubmit = (data: Inputs) => {
    setIsShowMessage(false);

    passwordRecovery(data).then(() => setIsShowMessage(true));
  };

  return (
    <>
      {isShowMessage && !error && (
        <span className={cn("form__comfirm-message")}>
          Email with instructions has been sent. Please check your mailbox for details
        </span>
      )}

      <form onSubmit={handleSubmit(onSubmit)} className={cn("form")}>
        {!!error && <span className={cn("form__error")}>{error}</span>}
        <Input
          id="email"
          label="E-mail"
          type="mail"
          icon="mail"
          myRef={register}
          error={errors.email}
        />
        <div className={cn("form__btn")}>
          <Button
            isSubmit
            text="Reset Password"
            isLoading={isLoading}
            variant="fullWidth"
          />
        </div>
      </form>
    </>
  );
};

export default ResetPassword;
