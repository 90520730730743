import React from "react";
// hooks
import { useHistory } from "react-router-dom";
import { useHolidays } from "hooks/useHolidays";
// redux
import { useDispatch } from "react-redux";
import { setSelectedDate } from "store/reducers/selectedDate";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// icons
import schedIcon from "assets/icons/days/sched.svg";
import notSchedIcon from "assets/icons/days/notSched.svg";
import skipIcon from "assets/icons/days/skip.svg";
// utils
import { getDayName, getDayNumber, getDayForUrl } from "utils/date";
import { getRoomUrl } from "utils/requests";

interface IDay {
  date: Date;
  status: string;
  roomId?: number | string;
  isActive: boolean;
}

const getImage = (status: string) => {
  switch (status) {
    case "scheduled":
      return schedIcon;
    case "not scheduled": {
      return notSchedIcon;
    }
    default: {
      return skipIcon;
    }
  }
};

const cn = classNames.bind(styles);

const Day: React.FC<IDay> = ({ date, status, roomId, isActive }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isHoliday } = useHolidays();

  const handleClick = () => {
    dispatch(setSelectedDate(date));

    roomId && history.push(getRoomUrl(getDayForUrl(date), roomId));
  };

  return (
    <div
      className={cn("day", status, isActive && "active-day")}
      onClick={handleClick}
    >
      <div className={cn("day__date", { day__date_holiday: isHoliday(date) })}>
        <span className={cn("day__date-dayName", isActive && "active")}>
          {getDayName(date)}
        </span>
        <span className={cn("day__date-month")}>{getDayNumber(date)}</span>
      </div>
      <div className={cn("day__status")}>
        <span className={cn("day__status-icon")}>
          <img src={getImage(status)} alt="" />
        </span>
        <span className={cn("day__status-name")}>{status}</span>
      </div>
    </div>
  );
};

export default Day;
