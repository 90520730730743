import React, { useMemo } from "react";
// hooks
import { useUser } from "hooks/useUser";
// routing
import { RouteComponentProps } from "react-router-dom";
// components
import AuthContainer from "components/Auth";
import NewPassword, { Inputs } from "components/Forms/NewPassword";

const Invite = (props: RouteComponentProps) => {
  const { search } = props.location;

  const { isLoading, inviteUser, error } = useUser();

  const token = useMemo(() => new URLSearchParams(search).get("token"), [
    search,
  ]);

  const email = useMemo(() => new URLSearchParams(search).get("email"), [
    search,
  ]);

  const onSubmit = (data: Inputs) => {
    inviteUser({
      password: data.password,
      passwordConfirmation: data.repeatPassword,
      token,
      email,
    });
  };

  return (
    <AuthContainer title="Join the Food World">
      {token && email ? (
        <NewPassword isLoading={isLoading} error={error} onSubmit={onSubmit}/>
      ) : (
        <div>
          <span>Get an invite from the administrator</span>
        </div>
      )}
    </AuthContainer>
  );
};

export default Invite;
