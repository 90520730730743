import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "../../field-style.module.scss";
// types
import { FieldError } from "react-hook-form";

interface IInput {
  id?: string;
  label?: string;
  myRef?: any;
  error?: FieldError | undefined;
  type?: string;
  defaultValue?: string;
  name?: string;
}

const cn = classNames.bind(styles);

const Input: React.FC<IInput> = ({
  error,
  label,
  id,
  myRef,
  type = "text",
  defaultValue,
  name,
}) => {
  return (
    <div className={cn("input-wrapper-simple")}>
      <div className={cn("field", "simple", error && "field-error")}>
        <input
          type={type}
          className={cn("field__input-simple")}
          placeholder={label}
          name={id}
          id={id}
          ref={myRef()}
          defaultValue={defaultValue}
        />
      </div>
      {error && (
        <span className={cn("field__error-simple")}>{error?.message}</span>
      )}
    </div>
  );
};

export default Input;
