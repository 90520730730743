import React from "react";
// components
import AuthContainer from "components/Auth";
import ResetPasswordForm from "components/Forms/ResetPassword";

const ResetPassword = () => {
  return (
    <AuthContainer
      title="Reset your password?"
      subTitle="Enter the e-mail you have registered with. After check your e-mail with further instructions on resetting your password."
    >
      <ResetPasswordForm />
    </AuthContainer>
  );
};

export default ResetPassword;
