import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "../styles.module.scss";
// icons
import backIcon from "assets/icons/back.svg";

interface IBackBtn {
  onClick?: () => void;
  text: string;
}

const cn = classNames.bind(styles);

const BackBtn: React.FC<IBackBtn> = ({ onClick, text }) => {
  return (
    <button className={cn("back-btn")} onClick={onClick} type="button">
      <span className={cn("back-btn__icon-wrapper")}>
        <img src={backIcon} alt="" className={cn("back-btn__icon")} />
      </span>
      {text}
    </button>
  );
};

export default BackBtn;
