import React from "react";
// redux
import { useSelector } from "react-redux";
import { selectDate } from "store/reducers/selectedDate/selectors";
// utils
import { getMonthYear } from "utils/date";
import { getNextMonth } from "./moment";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// icons
import arrowRightIcon from "assets/icons/selector/arrow-right.svg";

interface IMonth {
  onClick: (arg_0: Date) => void;
}
const cn = classNames.bind(styles);

const Month: React.FC<IMonth> = ({ onClick }) => {
  const { date } = useSelector(selectDate);

  return (
    <div className={cn("month")}>
      <span
        className={cn("month-btn", "left-btn")}
        onClick={() => onClick(getNextMonth(date, -1))}
      >
        <img src={arrowRightIcon} alt="" className={cn("month-btn__icon")} />
      </span>
      <div className={cn("month-wrapper")}>
        <span
          className={cn("month__title", "active-month")}
          onClick={() => onClick(date)}
        >
          {getMonthYear(date)}
        </span>
        <span
          className={cn("month__title")}
          onClick={() => onClick(getNextMonth(date, 1))}
        >
          {getMonthYear(getNextMonth(date, 1))}
        </span>
        <span
          className={cn("month__title")}
          onClick={() => onClick(getNextMonth(date, 2))}
        >
          {getMonthYear(getNextMonth(date, 2))}
        </span>
      </div>
      <span
        className={cn("month-btn", "right-btn")}
        onClick={() => onClick(getNextMonth(date, 1))}
      >
        <img src={arrowRightIcon} alt="" className={cn("month-btn__icon")} />
      </span>
    </div>
  );
};

export default Month;
