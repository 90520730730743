import React from "react";
// redux
import { useSelector } from "react-redux";
import { selectClassroom } from "store/reducers/classroom/selectors";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cn = classNames.bind(styles);

const SelectedClass = () => {
  const { label } = useSelector(selectClassroom);
  return (
    <span className={cn("classroom")}>
      Classroom: <span className={cn("classroom-name")}>{label}</span>
    </span>
  );
};

export default SelectedClass;
