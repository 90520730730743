import moment from "moment";

export const numberWeekOfMonth = (currentDate: Date) => {
  const m = moment(currentDate);

  const firstDayOfMonth = getFirstDay(currentDate);

  const firstDayOfWeek = firstDayOfMonth.clone().startOf("week");

  const offset = firstDayOfMonth.diff(firstDayOfWeek, "days");

  return Math.ceil((m.date() + offset) / 7);
};

const getFirstDay = (currentDate: Date) => {
  const momentDate = moment(currentDate);

  const firstDayNumber = momentDate.date(1).day();

  const startWeekDay = firstDayNumber === 6 ? 3 : firstDayNumber === 0 ? 2 : 1;

  return moment(momentDate).date(startWeekDay);
};

export const calcWeeksInMonth = (currentDate: Date) => {
  const momentDate = moment(currentDate);

  const dateFirst = getFirstDay(currentDate);

  const dateLast = moment(momentDate).date(momentDate.daysInMonth());

  const startWeek = dateFirst.isoWeek();
  const endWeek = dateLast.isoWeek();

  if (endWeek < startWeek) {
    return dateFirst.weeksInYear() - startWeek + 1 + endWeek;
  } else {
    return endWeek - startWeek + 1;
  }
};

export const firstDayOfWeek = (weekNumber: number, currentDate: Date) => {
  const momentDate = moment(currentDate);

  const firstDayNumber = moment(momentDate).date(1).day();

  const startWeekDay = firstDayNumber === 6 ? 3 : firstDayNumber === 0 ? 2 : 1;

  const dateFirst = getFirstDay(currentDate);

  if (weekNumber === 0) {
    return dateFirst.toDate();
  } else {
    return moment(momentDate)
      .date(7 * weekNumber - dateFirst.day() + startWeekDay + 1)
      .toDate();
  }
};

export const getDayOfStartWeek = (currentDate: Date) => {
  const weeks: Date[] = [];

  const m = moment(currentDate);

  const firstDayOfMonth = m.clone().startOf("month");

  const _firstDayOfMonthInWeekend = getFirstDay(currentDate);

  if (firstDayOfMonth.day() !== 6 && firstDayOfMonth.day() !== 0) {
    weeks.push(firstDayOfMonth.toDate());
  } else if (firstDayOfMonth.day() === 0) {
    weeks.push(_firstDayOfMonthInWeekend.toDate());
  }

  const lastDayOfMonth = m.clone().endOf("month");

  const current = firstDayOfMonth.clone();

  while (current.day(7 + 1).isBefore(lastDayOfMonth)) {
    weeks.push(current.clone().toDate());
  }
  return weeks;
};

export const weekNumberIsNow = (currentDate: Date, weeks: Date[]) => {};
