import { createSlice } from "@reduxjs/toolkit";
import { TSelectedDate } from "./type";

const initialState: TSelectedDate = {
  date: new Date(),
};

const selectedDateSlice = createSlice({
  name: "selectedDate",
  initialState,
  reducers: {
    setSelectedDate: (state, { payload }) => {
      state.date = payload;
    },
  },
});

const { reducer, actions } = selectedDateSlice;

export const { setSelectedDate } = actions;

export default reducer;
