import React, { memo } from "react";
// libs
import { FieldArray } from "formik";
// components
import Input from "components/Fields/Input/Formik"
import Add from "components/Button/Add"
import Remove from "components/Button/Remove"
import TSectionTitle from "components/Table/TSectionTitle"
// types
import { FormikHandlers } from 'formik/dist/types'
import { TOrderRequirement } from 'pages/Customer/Room/type'
// styles
import styles from "./styles.module.scss"
import classNames from "classnames/bind";

const cn = classNames.bind(styles);

interface ISpecialRequirements {
  onChange: FormikHandlers["handleChange"]
  value: TOrderRequirement[]
  styleWrap?: string
  setValues: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const onlyNumbers = (value: string | null) =>
  value ? (value === '0' ? value : value.replace(/(^[\D0]+)|(\D)/g, '')) : value

const SpecialRequirements: React.FC<ISpecialRequirements> = ({ onChange, value, styleWrap, setValues }) => (
  <div className={cn("special-requirements", styleWrap)}>
    <TSectionTitle title="Special requirements" />

    <FieldArray name="specialRequirements">
      {({ push, remove }) => (
        value.map((i: TOrderRequirement, index: number) => (
          <div key={index} className={cn("special-requirements__fields")}>
            <Input
              id={`specialRequirements.${index}.menuItemsRequirements`}
              label="Menu Items"
              wrapStyle={cn("field", "menu-items")}
              inputStyle={cn("input")}
              values={value[index].menuItemsRequirements}
              onChange={onChange}
            />
            <Input
              id={`specialRequirements.${index}.allergiesRequirements`}
              label="Allergies"
              wrapStyle={cn("field", "allergies")}
              inputStyle={cn("input")}
              values={value[index].allergiesRequirements}
              onChange={onChange}
            />
            <Input
              type="number"
              step="1"
              id={`specialRequirements.${index}.portionsRequirements`}
              label="Portions"
              wrapStyle={cn("field", "portions")}
              inputStyle={cn("input")}
              values={value[index].portionsRequirements}
              onChange={({ target }) => {
                setValues(`specialRequirements.${index}.portionsRequirements`, onlyNumbers(target?.value ?? ""))
              }}
            />
            {index === value.length - 1 ? (
              <Add
                onClick={() => push({ menuItemsRequirements: '', allergiesRequirements: '', portionsRequirements: '' })}
                style={cn("action")}
                text="+"
              />
            ) : (
              <Remove
                onClick={() => remove(index)}
                style={cn("action")}
                text="-"
              />
            )}
          </div>
        ))
      )}
    </FieldArray>
  </div>
);

export default memo(SpecialRequirements);
