import { createSlice } from "@reduxjs/toolkit";
import { TUser } from "./type";

const initialState: TUser = {
  token: null,
  customer: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      return payload;
    },
    removeUser: () => {
      return initialState;
    },
    setCustomer: (state, { payload }) => {
      state.customer = payload;
    },
  },
});

const { reducer, actions } = userSlice;

export const { setUser, removeUser, setCustomer } = actions;

export default reducer;
