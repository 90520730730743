import * as yup from "yup";

export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Please enter a registered email"),
  password: yup.string().required("Please enter a password"),
});

export const resetPassword = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Please enter a registered email"),
});

export const inviteSchema = yup.object().shape({
  password: yup
    .string()
    .required("Please enter a password")
    .min(8, "Password must have at least 8 characters "),
  repeatPassword: yup
    .string()
    .required("Please repeat a password")
    .oneOf([yup.ref("password")], "Passwords must match"),
});

export const emailSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Please enter a registered email"),
  repeatEmail: yup
    .string()
    .required("Please repeat a email")
    .oneOf([yup.ref("email")], "Passwords must match"),
  password: yup
    .string()
    .required("Please enter a password")
    .min(8, "Password must have at least 8 characters "),
});

export const profileSchema = yup.object().shape({
  name: yup.string().required("Please enter a name"),
  companyName: yup.string().required("Please enter a name"),
  address: yup.string().required("Please enter a name"),
  city: yup.string().required("Please enter a name"),
  state: yup.string().required("Please enter a name"),
  zip: yup.string().required("Please enter a name"),
});

export const passwordSchema = yup.object().shape({
  newPasswordForm: yup
    .string()
    .required("Please enter a password")
    .min(8, "Password must have at least 8 characters "),
  newPasswordConfirmation: yup
    .string()
    .required("Please repeat a password")
    .oneOf([yup.ref("newPasswordForm")], "Passwords must match"),
});
