const urlRequest = `${process.env.REACT_APP_API_URL}/api/customer`;

// post
export const urlPostInvite = `${urlRequest}/accept-invite`;
export const urlPostSignIn = `${urlRequest}/signin`;
export const urlPostPasswordRecovery = `${urlRequest}/password/recovery`;
export const urlPostPasswordUpdate = `${urlRequest}/password/update`;
export const urlPostEmailChange = `${urlRequest}/email/change`;
export const urlPostProfileUpdate = `${urlRequest}/profile`;
export const urlPostPasswordChange = `${urlRequest}/profile/password`;
export const urlOrders = `${urlRequest}/orders`;
export const urlHolidays = `${urlRequest}/holidays`;

export const urlDailyMenu = `${urlRequest}/daily-menus`;

export const urlGetCustomer = `${urlRequest}/profile`;

export const getRoomUrl = (date: string, roomId: number | string) =>
  `/customer/order/room/daily-menus?menuDate=${date}&roomId=${roomId}`;

const axios = require("axios");

export const postQuery = (url: string, data: any, headers?: any) =>
  axios.post(url, data, headers);

export const getQuery = (url: string, params?: any) => axios.get(url, params);

export const putQuery = (url: string, data: any, headers?: any) =>
  axios.put(url, data, headers);
