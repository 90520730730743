import React from "react";
// components
import CopyRight from "components/CopyRight";
import Title from "components/Title";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// images
import LogoImage from "assets/images/Logo.png";

interface IAuth {
  children: any;
  title: string;
  subTitle?: string;
}

const cn = classNames.bind(styles);

const Auth: React.FC<IAuth> = ({ title, children, subTitle }) => {
  return (
    <div className={cn("fullpage", "background")}>
      <div className={cn("auth")}>
        <main className={cn("main")}>
          <img src={LogoImage} alt="" />
          <div className={cn("title")}>
            <Title text={title} />
            <span className={cn("sub-title")}>{subTitle}</span>
          </div>
          {children}
        </main>
        <CopyRight />
      </div>
    </div>
  );
};

export default Auth;
