import moment from "moment";
import momentTZ from "moment-timezone";

export const getCellHeaderDay = (date: Date | string) => {
  return `${new Date(date).getDate()}`;
};

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const dayNames = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getDayForUrl = (date: Date) =>
  `${moment(date).format("YYYY")}-${moment(date).format("MM")}-${moment(
    date
  ).format("DD")}`;

export const getMonthYear = (date: Date) =>
  `${monthNames[date.getMonth()]} ${date.getFullYear()}`;

export const getDayName = (date: Date) => `${dayNames[date.getDay()]}`;

export const getDayNumber = (date: Date) =>
  `${monthNames[date.getMonth()]} ${date.getDate()}`;

export const getMonthDayYear = (date: Date) =>
  `${monthNames[date.getMonth()]}  ${date.getDate()}, ${date.getFullYear()}`;

export const getDayNameMontn = (date: Date | string) =>
  `${dayNames[momentTZ(date).toDate().getDay()]}, ${
    monthNames[momentTZ(date).toDate().getMonth()]
  } ${momentTZ(date).toDate().getDate()}`;

export const getFirstAndLastDayOfMonth = (date: Date) => {
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  const startDay =
    firstDay.getFullYear() +
    "-" +
    ("0" + (firstDay.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + firstDay.getDate()).slice(-2);

  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  const endDay =
    lastDay.getFullYear() +
    "-" +
    ("0" + (lastDay.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + lastDay.getDate()).slice(-2);
  return { startDay, endDay };
};

export const getNextDayUrl = (date: string | Date) => {
  // const day = new Date(date);
  // const numberDayOfTheWeek = day.getDay();
  // const plusDayCount = numberDayOfTheWeek === 5 ? 3 : 1;
  // const newDay = new Date(day);
  // newDay.setDate(day.getDate() + plusDayCount);
  // return getDayForUrl(newDay);

  const numberDaysToAdd =
    momentTZ.tz(date, "America/Chicago").format("dddd") === "Friday" ? 3 : 1;

  return moment
    .tz(date, "America/Chicago")
    .add(numberDaysToAdd, "d")
    .format("YYYY-MM-DD");
};

export const getDayNameMonthNumber = (date: string | Date) => {
  return `${getDayName(new Date(date)).slice(0, 3)} ${getDayNumber(
    new Date(date)
  )}`;
};

export const getDayNameForNotification = (date: string | Date) =>
  `${moment(date).format("ddd MMMM DD")}`;

export const isCanOrder = (currentDate: string | Date) => {
  const m = momentTZ(currentDate).format("YYYY-MM-DD");

  if (
    momentTZ.tz(currentDate, "America/Chicago").format("dd") === "Mo" ||
    momentTZ.tz(currentDate, "America/Chicago").format("dd") === "Tu" ||
    momentTZ.tz(currentDate, "America/Chicago").format("dd") === "We"
  ) {
    const lastOrderDate = momentTZ
      .tz(m, "America/Chicago")
      .hours(7)
      .subtract(5, "day");

    return lastOrderDate.isAfter(momentTZ.tz("America/Chicago"));
  } else {
    const lastOrderDate = momentTZ
      .tz(m, "America/Chicago")
      .hours(7)
      .subtract(3, "day");

    return lastOrderDate.isAfter(momentTZ.tz("America/Chicago"));
  }
};

export const isSameDate = (date1: string | Date, date2: string | Date) =>
  moment(date1).isSame(moment(date2), "day");
