import React, { useEffect, useState } from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// utils
import { getDayNameForNotification } from "utils/date";

interface INotification {
  status: string;
  date: Date | string;
}

const cn = classNames.bind(styles);

const Notification: React.FC<INotification> = ({ status, date }) => {
  const [isShow, setIsShow] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setIsShow(false);
    }, 3500);
    return () => setIsShow(false);
  }, []);

  return (
    <div className={cn("notification", !isShow && "hide")}>
      <span className={cn("notification__date")}>
        {/* {getDayNameMonthNumber(date)} */}
        {getDayNameForNotification(date)}
      </span>{" "}
      is{" "}
      {status === "skipped" ? (
        <span>skipped</span>
      ) : (
        <span>
          <span className={cn("notification__succes")}>successfully</span>{" "}
          scheduled
        </span>
      )}
    </div>
  );
};

export default Notification;
