import { useState } from "react";
// api
import { postQuery, getQuery, putQuery } from "utils/requests";

export const useRequest = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const postRequest = async (url: string, data: any, config?: any) => {
    try {
      setError(null);
      setIsLoading(true);

      const result = await postQuery(url, data, config);
      setIsLoading(false);
      return result;
    } catch (err) {
      const { data } = err.response;

      setError(data.errors ? data.errors[0].message : data.message);
      setIsLoading(false);
    }
  };

  const getRequest = async (url: string, data?: any) => {
    try {
      setError(null);
      setIsLoading(true);

      const result = await getQuery(url, data);

      setIsLoading(false);
      return result;
    } catch (err) {
      const { data } = err.response;

      setError(data.errors ? data.errors[0].message : data.message);
      setIsLoading(false);
    }
  };

  const putRequest = async (url: string, data: any, config?: any) => {
    try {
      setError(null);
      setIsLoading(true);

      const result = await putQuery(url, data, config);
      setIsLoading(false);

      return result;
    } catch (err) {
      const { data } = err.response;

      setError(data.errors ? data.errors[0].message : data.message);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    postRequest,
    getRequest,
    putRequest,
  };
};
