import React, { useMemo } from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// utils
import {
  numberWeekOfMonth,
  calcWeeksInMonth,
  getDayOfStartWeek,
  weekNumberIsNow,
} from "./moment";

interface IWeeks {
  date: Date;
  onWeekClick: (arg_0: Date) => void;
}

const cn = classNames.bind(styles);

const Weeks: React.FC<IWeeks> = ({ date, onWeekClick }) => {
  const weekIsNow = useMemo(() => numberWeekOfMonth(date), [date]);

  const countWeeks = calcWeeksInMonth(date);

  const romanNumbers = ["Ⅰ", "Ⅱ", "Ⅲ", "Ⅳ", "Ⅴ", "Ⅵ", "Ⅶ"];

  const weeks = getDayOfStartWeek(date);

  weekNumberIsNow(date, weeks);

  const handleClick = (weekNumber: number) => onWeekClick(weeks[weekNumber]);

  return (
    <div className={cn("weeks")}>
      {[...Array(countWeeks)].map((el: any, index: number) => (
        <span
          key={index}
          onClick={() => handleClick(index)}
          className={cn("weeks-item", weekIsNow === index + 1 && "active")}
        >
          Week {romanNumbers[index]}
        </span>
      ))}
    </div>
  );
};

export default Weeks;
