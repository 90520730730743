import { createSlice } from "@reduxjs/toolkit";
import { TClassRoom } from "./type";

const initialState: TClassRoom = {
  value: "",
  label: "",
};

const classroomSlice = createSlice({
  name: "classroom",
  initialState,
  reducers: {
    setClassroom: (state, { payload }) => {
      return payload;
    },
    removeClassroom: () => {
      return initialState;
    },
  },
});

const { reducer, actions } = classroomSlice;

export const { setClassroom, removeClassroom } = actions;

export default reducer;
