import React, { useState, useEffect } from "react";
// components
import Aside from "components/Aside";
import Header from "components/Header";

interface IAsideWrapper {
  children: JSX.Element;
}

const AsideWrapper: React.FC<IAsideWrapper> = ({ children }) => {
  const [isOpenMenu, setIsOpenmenu] = useState<boolean>(false);

  useEffect(() => {}, []);
  return (
    <main className={"flex-row"}>
      <Aside isOpen={isOpenMenu} />
      <Header onClick={() => setIsOpenmenu(!isOpenMenu)} isOpen={isOpenMenu} />
      <div className="content">{children}</div>
    </main>
  );
};

export default AsideWrapper;
