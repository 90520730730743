import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "../styles.module.scss";
// icon
import cancelIcon from "assets/icons/cancel.svg";

interface ICancel {
  onClick?: () => void;
  text: string;
  type?: "reset" | "button";
}

const cn = classNames.bind(styles);

const Cancel: React.FC<ICancel> = ({ onClick, text, type = "button" }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={cn("cancel", "cancel-btn")}
    >
      {text}
      <span className={cn("button-icon")}>
        <img src={cancelIcon} alt="" className={cn("button-icon-img")} />
      </span>
    </button>
  );
};

export default Cancel;
