import React from "react";
// icons
import lockIcon from "assets/icons/lock.svg";
import mailIcon from "assets/icons/mail.svg";
// styles
import classNames from "classnames/bind";
import styles from "../../field-style.module.scss";
// types
import { FieldError } from "react-hook-form";

const cn = classNames.bind(styles);

interface IInput {
  id: string;
  label?: string;
  type?: string;
  icon?: string;
  myRef?: any;
  error?: FieldError | undefined;
}

const getIcon = (icon: string) => {
  switch (icon) {
    case "mail":
      return mailIcon;
    case "lock":
      return lockIcon;
    default:
      return undefined;
  }
};

const Input: React.FC<IInput> = ({
  id,
  label,
  type = "text",
  icon,
  myRef,
  error,
}) => {
  return (
    <div className={cn("input-wrapper")}>
      <div className={cn("field", "label", error && "field-error")}>
        {icon && (
          <div className={cn("field__icon")}>
            <img src={getIcon(icon)} alt="" />
          </div>
        )}
        <input
          type={type}
          className={cn("field__input-simple")}
          placeholder={label}
          name={id}
          id={id}
          ref={myRef}
        />
      </div>
      {error && <span className={cn("field__error")}>{error?.message}</span>}
    </div>
  );
};

export default Input;
