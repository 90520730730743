import React, { useMemo } from "react";
// hooks
import { useUser } from "hooks/useUser";
// routing
import { RouteComponentProps } from "react-router-dom";
// components
import AuthContainer from "components/Auth";
import NewPassword, { Inputs } from "components/Forms/NewPassword";

export type TPasswordUpdate = {
  password: string;
  passwordConfirmation: string;
  token: string;
};

export const Restore = (props: RouteComponentProps) => {
  const { search } = props.location;

  const { isLoading, error, newPassword } = useUser();

  const token = useMemo(() => new URLSearchParams(search).get("token"), [
    search,
  ]);

  const onSubmit = (data: Inputs) => {
    if (token) {
      newPassword({
        password: data.password,
        passwordConfirmation: data.repeatPassword,
        token,
      });
    }
  };

  return (
    <AuthContainer title="Reset your password">
      {token ? (
        <NewPassword isLoading={isLoading} error={error} onSubmit={onSubmit} />
      ) : (
        <div>
          <span>Get an invite from the administrator</span>
        </div>
      )}
    </AuthContainer>
  );
};

export default Restore;
