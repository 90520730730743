import {
  TOrderInputs,
  TDailyMenuItemsSerialized,
  TBeveragesSerialized,
} from "./type";

export const getInputs: (
  data: TOrderInputs
) => {
  dailyMenuItems: TDailyMenuItemsSerialized[];
  orderBeverages: TBeveragesSerialized[];
} = (data) => {
  const dailyMenuItems: TDailyMenuItemsSerialized[] = [];
  const orderBeverages: TBeveragesSerialized[] = [];

  const { dailyMenu, beverages } = data;

  Object.keys(dailyMenu).map(
    (key) =>
      dailyMenu[key] &&
      dailyMenuItems.push({
        dailyMenuItemId: key.slice(5),
        quantity: Math.abs(dailyMenu[key]),
      })
  );

  Object.keys(beverages).map(
    (key) =>
      beverages[key] &&
      orderBeverages.push({
        customerBeverageId: key.slice(5),
        quantity: beverages[key],
      })
  );

  return { dailyMenuItems, orderBeverages };
};
