import React from "react";
// hooks
import { useHolidays } from "hooks/useHolidays";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// utils
import { getCellHeaderDay } from "utils/date";
import { getTitle, getImage } from "../dayEvent";

interface IDayHeader {
  date: Date | string;
  status?: string;
}

const cn = classNames.bind(styles);

const DayHeader: React.FC<IDayHeader> = ({ date, status }) => {
  const { isHoliday } = useHolidays();

  return (
    <div
      className={cn("day-header", status, {
        checked: status,
        holiday: isHoliday(date),
      })}
    >
      <div className={cn("day-header__date")}>
        <span>{getCellHeaderDay(date)}</span>
      </div>
      <div className={cn("day-header__status")}>
        <span className={cn("day-header__status-img")}>
          <img src={getImage(status)} alt="" />
        </span>
        <span className={cn("day-header__status-name")}>
          {getTitle(status)}
        </span>
      </div>
    </div>
  );
};

export default DayHeader;
