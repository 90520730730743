import React, { ReactNode } from "react";
// styles
import styles from "../styles.module.scss";
import classNames from "classnames/bind";
// assets
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left-filled.svg";

interface ISecondaryBtn {
  text: string;
  icon?: ReactNode;
  onClick?: () => void;
}

const cn = classNames.bind(styles);

const SecondaryBtn: React.FC<ISecondaryBtn> = ({ text, icon, onClick }) => {
  return (
    <button className={cn("secondary-btn")} onClick={onClick}>
      {icon || <ArrowLeftIcon />} {text}
    </button>
  );
};

export default SecondaryBtn;
