import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "../styles.module.scss";

interface IRemoveBtn {
  onClick?: () => void;
  text: string;
  type?: "submit" | "button";
  style?: string
}

const cn = classNames.bind(styles);

const RemoveBtn: React.FC<IRemoveBtn> = ({
  onClick,
  text,
  style,
  type = "button",
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      className={cn("cancel", "circle-btn", style)}
    >
      {text}
    </button>
  );
};

export default RemoveBtn;
