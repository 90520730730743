import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// utils
import { momentDate } from "components/Select/WeekDays/moment";

interface IWeekRange {
  date: Date;
}

const cn = classNames.bind(styles);

const WeekRange: React.FC<IWeekRange> = ({ date }) => {
  const { selectedWeek } = momentDate(date);
  return <span className={cn("selected-week")}>{selectedWeek}</span>;
};

export default WeekRange;
