import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

interface ILoader {
  isLoading: boolean;
  children: any;
}

const cn = classNames.bind(styles);

const Loader: React.FC<ILoader> = ({ isLoading, children }) => {
  return (
    <>
      {isLoading ? (
        <div className={cn("loader")}>
          <span className={cn("circle")} />
        </div>
      ) : (
        <div> {children}</div>
      )}
    </>
  );
};

export default Loader;
