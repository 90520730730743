import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// icons
import arrowIcon from "assets/icons/arrow.svg";

const cn = classNames.bind(styles);
interface IButton {
  text: string;
  onClick?: () => void;
  isSubmit?: boolean;
  isLoading?: boolean;
  variant?: "default" | "rounded" | "fullWidth" | "square";
}

const Button: React.FC<IButton> = ({
  text,
  onClick,
  isSubmit,
  isLoading,
  variant = "default",
}) => {
  return (
    <button
      type={isSubmit ? "submit" : "button"}
      onClick={onClick}
      className={cn("button", {
        button_rounded: variant === "rounded",
        "auth-btn": variant === "fullWidth",
        button_square: variant === "square",
      })}
    >
      {text}
      {isLoading ? (
        <span className={cn("loader", "open")} />
      ) : (
        variant === "default" && (
          <img src={arrowIcon} alt="" className={cn("icons")} />
        )
      )}
    </button>
  );
};

export default Button;
