import React from "react";
// components
import Input from "components/Fields/Input/Simple";
// types
import { FieldError } from "react-hook-form";
// styles
import classNames from "classnames/bind";
import styles from "components/Forms/forms-styles.module.scss";

interface IMail {
  register: any;
  error?: FieldError | undefined;
  label: string;
  id: string;
}

const cn = classNames.bind(styles);

const Mail: React.FC<IMail> = ({ register, error, label, id }) => {
  return (
    <div className={cn("input-wrapper-simple")}>
      <div className={cn("form__item")}>
        <span className={cn("form__item--text")}>{label}</span>
        <div className={cn("form__item--field", "item-btn")}>
          <Input
            id={id}
            label={label}
            myRef={register}
            error={error}
            type="email"
          />
        </div>
      </div>
    </div>
  );
};

export default Mail;
