import React, { useCallback, useMemo, useRef, useState } from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
// redux
import { useSelector } from "react-redux";
import { selectDate } from "store/reducers/selectedDate/selectors";
import { selectClassroom } from "store/reducers/classroom/selectors";
// components
import Button from "components/Button";
import SecondaryBtn from "components/Button/Secondary";
import Dialog from "components/Dialog";
import ReactToPrint from "react-to-print";
// utils
import { getDayNumber } from "utils/date";
// assets
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import Table from "../Table";
import SelectedClass from "../SelectedClass";
import { TDailyMenu } from "../../pages/Customer/Room/type";

interface IPrintMenu {
  daily: TDailyMenu | null;
  menuDate: string | Date;
}

const cn = classNames.bind(styles);

const PrintMenu: React.FC<IPrintMenu> = ({ daily, menuDate }) => {
  const { date } = useSelector(selectDate);
  const classroom = useSelector(selectClassroom);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const pdfExportComponent = useRef<HTMLDivElement>(null);

  const handleDialogClose = useCallback(() => setDialogIsOpen(false), []);

  return (
    <>
      <Button
        text="Print Menu"
        variant="rounded"
        onClick={() => setDialogIsOpen(true)}
      />
      <Dialog isOpen={dialogIsOpen} handleClose={handleDialogClose}>
        <div className={cn("modal-content")}>
          <CloseIcon
            className={cn("modal-content__close")}
            onClick={handleDialogClose}
          />

          <h1 className={cn("month-title")}>{getDayNumber(date)}</h1>

          <div className={cn("modal-content__actions")}>
            <SelectedClass />

            <ReactToPrint
              trigger={() => {
                return <Button text="PRINT / PDF" variant="square" />;
              }}
              content={() => pdfExportComponent.current}
            />
          </div>
          {daily && daily.dailyMenuToMenuItems && (
            <div className={cn("modal-content__table-style")}>
              <Table
                dailyMenus={daily.dailyMenuToMenuItems}
                beverages={daily.customerBeverages}
                order={daily.order}
                menuDate={menuDate}
                isPrintMenu
              />
            </div>
          )}
        </div>
        <div className={cn("pdf-export-container")}>
          <div ref={pdfExportComponent}>
            <h1 className={cn("month-title")}>{getDayNumber(date)}</h1>

            <div>Classroom: {classroom.label}</div>

            {daily && daily.dailyMenuToMenuItems && (
              <div className={cn("modal-content__table-style")}>
                <Table
                  dailyMenus={daily.dailyMenuToMenuItems}
                  beverages={daily.customerBeverages}
                  order={daily.order}
                  menuDate={menuDate}
                  isPrintMenu
                />
              </div>
            )}
          </div>
        </div>
        <div className={cn("modal-actions")}>
          <SecondaryBtn
            text="Back"
            onClick={() => {
              setDialogIsOpen(false);
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default PrintMenu;
