import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

const cn = classNames.bind(styles);

const CopyRight: React.FC = () => {
  const yearNow = new Date().getFullYear();

  return (
    <footer className={cn("footer")}>
      <span className={cn("footer__copyright")}>
        © <span className={cn("footer__copyright-year")}>{yearNow}</span> BEYOND
        GREEN
      </span>
    </footer>
  );
};

export default CopyRight;
