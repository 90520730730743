import React from "react";
// components
import Input from "components/Fields/Input/Simple";
// types
import { FieldError } from "react-hook-form";
// styles
import classNames from "classnames/bind";
import styles from "components/Forms/forms-styles.module.scss";

interface IField {
  register?: any;
  error?: FieldError | undefined;
  label: string;
  id?: string;
  type?: string;
}

const cn = classNames.bind(styles);

const Field: React.FC<IField> = ({ register, error, label, id, type }) => {
  return (
    <div className={cn("form__item")}>
      <span className={cn("form__item--text")}>{label}</span>
      <div className={cn("form__item--field")}>
        <Input
          id={id}
          label={label}
          myRef={register}
          error={error}
          type={type}
        />
      </div>
    </div>
  );
};

export default Field;
