import React from "react";
// components
import Input from "components/Fields/Input/Disabled";
// styles
import classNames from "classnames/bind";
import styles from "components/Forms/forms-styles.module.scss";

const cn = classNames.bind(styles);

interface IDisabled {
  label: string;
  value?: string;
}

const Disabled: React.FC<IDisabled> = ({ label, value }) => {
  return (
    <div className={cn("form__item")}>
      <span className={cn("form__item--text")}>{label}</span>
      <div className={cn("form__item--field")}>
        <Input value={value} />
      </div>
    </div>
  );
};

export default Disabled;
