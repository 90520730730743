import React, { useEffect } from "react";
// components
import AsideWrapper from "components/Aside/AsideWrapper";
import UpdateEmail from "components/Forms/UpdateEmail";
import UpdatePassword from "components/Forms/UpdatePassword";
import UpdateProfileFormik from "components/Forms/UpdateProfileFormik";

const Profile: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <AsideWrapper>
      <div className="container">
        <div className="max-container">
          <div className="block">
            <UpdateProfileFormik />
          </div>
          <div className="block">
            <UpdateEmail />
          </div>
          <div className="block">
            <UpdatePassword />
          </div>
        </div>
      </div>
    </AsideWrapper>
  );
};

export default Profile;
