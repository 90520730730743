import { useCallback, useState } from "react";
// hooks
import { useDispatch, useSelector } from "react-redux";
import { useRequest } from "./useRequest";
// redux
import { setHolidays } from "store/reducers/holidays";
import { selectHolidays } from "store/reducers/holidays/selectors";
import { selectDate } from "store/reducers/selectedDate/selectors";
import { selectToken } from "store/reducers/user/selectors";
// api
import { urlHolidays } from "utils/requests";
// utils
import moment from "moment";
import { getDayForUrl, isSameDate } from "utils/date";

export const useHolidays = () => {
  const { isLoading, getRequest } = useRequest();
  const token = useSelector(selectToken);
  const { holidays } = useSelector(selectHolidays);
  const { date } = useSelector(selectDate);
  const dispatch = useDispatch();
  const [error, setError] = useState(null);

  const startDate = getDayForUrl(moment(date).startOf("month").toDate());
  const endDate = getDayForUrl(moment(date).endOf("month").toDate());

  const isHoliday = useCallback(
    (date) => holidays.some(({ holidayDate }) => isSameDate(date, holidayDate)),
    [holidays]
  );

  const fetchHolidays = useCallback(async () => {
    if (token) {
      try {
        const response = await getRequest(urlHolidays, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { startDate, endDate },
        });

        dispatch(setHolidays(response.data));
      } catch (err) {
        setError(err);
      }
    }
  }, [dispatch, startDate, endDate, token]);

  return { isLoading, error, fetchHolidays, holidays, isHoliday };
};
