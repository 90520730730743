import React from "react";
// hooks
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useEmail } from "hooks/useEmail";
// components
import MailField from "components/Forms/Item/MailField";
import Field from "components/Forms/Item/Field";
import SaveBtn from "components/Button/Save";
import CancelBtn from "components/Button/Cancel";
import Disabled from "components/Forms/Item/Disable";
import SubTitle from "components/SubTitle";
// selector
import { selectEmail } from "store/reducers/user/selectors";
// utils
import { emailSchema } from "utils/validation";
// styles
import classNames from "classnames/bind";
import styles from "components/Forms/styles.module.scss";

type Inputs = {
  email: string;
  repeatEmail: string;
  password: string;
};

const cn = classNames.bind(styles);

const UpdateEmail: React.FC = () => {
  const email = useSelector(selectEmail);
  const { isLoading, error, response, changeEmail } = useEmail();

  const { register, handleSubmit, errors, setValue } = useForm<Inputs>({
    validationSchema: emailSchema,
  });

  const onSubmit = (data: Inputs) => {
    changeEmail(data.email, data.password).then((res: boolean | undefined) => {
      if (res) {
        setValue("email", "");
        setValue("repeatEmail", "");
        setValue("password", "");
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn("form")}>
       <SubTitle text="Update Email" />
      <p className={cn("form__message")}>
        <span className={cn("form__message-error")}>{error}</span>
        <span className={cn("form__message-response")}>{response}</span>
      </p>
      <Disabled label="User email" value={email} />
      <MailField
        register={register}
        error={errors.email}
        label="New Email"
        id="email"
      />

      <MailField
        register={register}
        error={errors.repeatEmail}
        label="Confirm New Email"
        id="repeatEmail"
      />
      <Field
        register={register}
        error={errors.password}
        label="Password"
        id="password"
        type="password"
      />

      <div className={cn("buttons")}>
        <CancelBtn text="Cancel" type="reset" />
        <SaveBtn type="submit" text="Save" isLoading={isLoading} />
      </div>
    </form>
  );
};

export default UpdateEmail;
