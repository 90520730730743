import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

interface IChechBox {
  name: string;
  checked?: boolean;
  text: string;
  myRef?: any;
}

const cn = classNames.bind(styles);

const ChechBox: React.FC<IChechBox> = ({ name, checked, text, myRef }) => {
  return (
    <label className={cn("checkbox")}>
      <input
        type="checkbox"
        className={cn("input")}
        name={name}
        checked={checked}
        ref={myRef}
      />
      <span className={cn("checkTitle")}>{text}</span>
    </label>
  );
};

export default ChechBox;
