import React from "react";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";

interface ISubTitle {
  text: string;
}

const cn = classNames.bind(styles);

const SubTitle: React.FC<ISubTitle> = ({ text }) => {
  return <h2 className={cn("sub-title")}>{text}</h2>;
};
export default SubTitle;
