import { createSlice } from "@reduxjs/toolkit";
import { THolidays } from "./type";

const initialState: THolidays = {
  holidays: [],
};

const classroomSlice = createSlice({
  name: "holidays",
  initialState,
  reducers: {
    setHolidays: (state, { payload }) => {
      state.holidays = payload;
    },
  },
});

const { reducer, actions } = classroomSlice;

export const { setHolidays } = actions;

export default reducer;
