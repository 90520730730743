import { useCallback } from "react";
import { selectUser } from "store/reducers/user/selectors";
// redux
import { useSelector, useDispatch } from "react-redux";
import { setClassroom } from "store/reducers/classroom";

export const useRoom = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const setClassRoom = useCallback(
    (id: number | string) => {
      const classRoom = user?.customer?.rooms.find((room) => +room.id === +id);

      classRoom &&
        dispatch(setClassroom({ label: classRoom.name, value: classRoom.id }));
    },
    [dispatch, user]
  );
  return {
    setClassRoom,
  };
};
