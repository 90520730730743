import React from "react";
// hooks
import { useHistory } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
import { selectUser } from "store/reducers/user/selectors";
// routing
import { Switch, Route, Redirect } from "react-router-dom";
import { AUTH_ROUTES, CUSTOMER_ROUTES } from "pages/routes";
// pages
import Login from "./Login";
import ResetPassword from "./ResetPassword";

const Auth = () => {
  const user = useSelector(selectUser);
  const history = useHistory();

  const { token } = user;

  token && history.push(CUSTOMER_ROUTES.ORDER);

  return (
    <Switch>
      <Route exact path={AUTH_ROUTES.LOGIN} component={Login} />
      <Route
        exact
        path={AUTH_ROUTES.PASSWORD_RESET}
        component={ResetPassword}
      />

      <Redirect to={AUTH_ROUTES.LOGIN} />
    </Switch>
  );
};

export default Auth;
