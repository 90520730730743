import React, { FC } from 'react';
import styles from "../styles.module.scss"
import classNames from "classnames/bind";

const cn = classNames.bind(styles);

const TSectionTitle: FC<{ title: string }> = ({ title }) => (
  <p className={cn("section-title")}>{title}</p>
);

export default TSectionTitle;
