import momentTZ from "moment-timezone";

export const getNextMonth = (date: Date, numberNextMonth: number) => {
  const firstDayOfMonth = momentTZ(date)
    .add(numberNextMonth, "months")
    .clone()
    .startOf("month");

  const startWeekDay =
    firstDayOfMonth.day() === 6 ? 3 : firstDayOfMonth.day() === 0 ? 2 : 1;

  return momentTZ(firstDayOfMonth).date(startWeekDay).toDate();
};
