export const APP_ROUTES = {
  AUTH: "/auth",
  CUSTOMER: "/customer",
  INVITE: "/invite",
  RESTORE: "/password/restore",
};

export const AUTH_ROUTES = {
  LOGIN: `${APP_ROUTES.AUTH}/login`,
  PASSWORD_RESET: `${APP_ROUTES.AUTH}/password/recovery`,
};

export const CUSTOMER_ROUTES = {
  ORDER: `${APP_ROUTES.CUSTOMER}/order`,
  PROFILE: `${APP_ROUTES.CUSTOMER}/profile`,
  ROOM: `${APP_ROUTES.CUSTOMER}/order/room`,
};
