import { useState, useCallback } from "react";
// hooks
import { useHistory } from "react-router-dom";
// redux
import { setUser } from "store/reducers/user";
import { useDispatch } from "react-redux";
import { setClassroom } from "store/reducers/classroom";
// api
import {
  getQuery,
  postQuery,
  urlGetCustomer,
  urlPostInvite,
  urlPostPasswordRecovery,
  urlPostPasswordUpdate,
} from "utils/requests";
// storage
import { getLocalItem, getSessionItem, UUID } from "utils/storage";
// routing
import { APP_ROUTES } from "pages/routes";
// types
import { TNewPassword } from "components/Forms/NewPassword";
import { Inputs } from "components/Forms/ResetPassword";
import { TPasswordUpdate } from "pages/Restore";

export const useUser = (load: boolean = false) => {
  const [isLoading, setIsLoading] = useState<boolean>(load);
  const [error, setError] = useState<string | null>(null);

  const history = useHistory();

  const dispatch = useDispatch();

  const checkUser = useCallback(async () => {
    try {
      setIsLoading(true);
      const token = (await getLocalItem(UUID)) || getSessionItem(UUID);

      if (token) {
        const result = await getQuery(urlGetCustomer, {
          headers: { Authorization: `Bearer ${token}` },
        });

        dispatch(setUser({ token, customer: result.data }));

        if (result.data.rooms) {
          dispatch(
            setClassroom({
              label: result.data.rooms[0].name,
              value: result.data.rooms[0].id,
            })
          );
        }
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, [dispatch]);

  const inviteUser = useCallback(
    async (data: TNewPassword) => {
      try {
        setError(null);
        setIsLoading(true);

        const result = await postQuery(urlPostInvite, data);

        setIsLoading(false);
        if (result) {
          history.push(APP_ROUTES.AUTH);
        }
      } catch (err) {
        const { data } = err.response;

        setError(data.errors ? data.errors[0].message : data.message);
        setIsLoading(false);
      }
    },
    [history]
  );

  const passwordRecovery = useCallback(async (email: Inputs) => {
    try {
      setError(null);
      setIsLoading(true);

      await postQuery(urlPostPasswordRecovery, email);

      setIsLoading(false);
    } catch (err) {
      const { data } = err.response;

      setError(data.errors ? data.errors[0].message : data.message);
      setIsLoading(false);
    }
  }, []);

  const newPassword = useCallback(
    async (data: TPasswordUpdate) => {
      try {
        setIsLoading(true);
        const result = await postQuery(urlPostPasswordUpdate, data);

        if (result) {
          history.push(APP_ROUTES.AUTH);
        }

        setIsLoading(false);
      } catch (err) {
        const { data } = err.response;

        setError(data.errors ? data.errors[0].message : data.message);
        setIsLoading(false);
      }
    },
    [history]
  );

  return {
    isLoading,
    error,
    checkUser,
    inviteUser,
    passwordRecovery,
    newPassword,
  };
};
