import React from "react";
// hooks
import { useForm } from "react-hook-form";
import { useAuth } from "hooks/useAuth";
// routes
import { Link } from "react-router-dom";
import { AUTH_ROUTES } from "pages/routes";
// components
import Input from "components/Fields/Input/Label";
import Button from "components/Button";
import ChechBox from "components/Fields/Checkbox";
// styles
import classNames from "classnames/bind";
import styles from "../forms-styles.module.scss";
// api
import { urlPostSignIn } from "utils/requests";
// validation
import { loginSchema } from "utils/validation";

type Inputs = {
  email: string;
  password: string;
  isRemember: boolean;
};

const cn = classNames.bind(styles);

const LoginForm: React.FC = () => {
  const { register, handleSubmit, errors } = useForm<Inputs>({
    validationSchema: loginSchema,
    defaultValues: {
      isRemember: true,
      email: "",
      password: "",
    },
  });

  const { signIn, isLoading, error } = useAuth();

  const onSubmit = (data: Inputs) => {
    signIn({ ...data }, urlPostSignIn);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={cn("form")}>
      {error && <span className={cn("form__error")}>{error}</span>}
      <Input
        id="email"
        label="E-mail"
        type="email"
        icon="mail"
        myRef={register}
        error={errors.email}
      />
      <Input
        id="password"
        label="Password"
        type="password"
        icon="lock"
        myRef={register}
        error={errors.password}
      />

      <div className={cn("form__checkbox")}>
        <ChechBox name="isRemember" text="Remember me" myRef={register} />

        <Link to={AUTH_ROUTES.PASSWORD_RESET}>
          <span className={cn("form__forgot")}>Forgot password</span>
        </Link>
      </div>
      <div className={cn("form__btn")}>
        <Button
          isSubmit
          text="Place Order"
          isLoading={isLoading}
          variant="fullWidth"
        />
      </div>
    </form>
  );
};

export default LoginForm;
