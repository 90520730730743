import React from "react";
// hooks
import { useHistory } from "react-router-dom";
// components
import Header from "components/Header";
import Button from "components/Button";
// styles
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
//
import { APP_ROUTES } from "pages/routes";

const cn = classNames.bind(styles);

const NotFound: React.FC = () => {
  const history = useHistory();

  const backHome = () => history.push(APP_ROUTES.AUTH);
  return (
    <div className={cn("not-found")}>
      <Header isHideBurger />
      <div className={cn("not-found__content")}>
        <h1 className={cn("not-found__title")}>Oops...</h1>
        <p className={cn("not-found__text")}>
          We can't find the page you're looking for.
        </p>
        <Button text="Go back home" onClick={backHome} />
      </div>
    </div>
  );
};

export default NotFound;
